import React, { useState, useContext } from "react";
import { ApiLoginService, ApiService, ImgUrl } from "../services";
import GButton from "../Elements/GButton";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useSnackbar } from "notistack";
import GText from "../Elements/GText";
import { SettingsContext } from "../DataFactory/useSettings";

export default function Signin(props) {
  const { settings, setSettings } = useContext(SettingsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    username: "",
    password: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    if (props.data) {
      delete values.password;
    }
    console.log(values);

    ApiLoginService("POST", "c/login", values).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        getOauthToken(values);
      } else {
        setBtnLoading(false);
      }
      enqueueSnackbar(response.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const getOauthToken = (signinData) => {
    var refresh_token_data = {
      username: signinData.username,
      password: signinData.password,
      grant_type: "password",
      client_id: 2,
      client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
      provider: "customers",
    };
    console.log(refresh_token_data);

    ApiLoginService("POST", "oauth/token", refresh_token_data)
      .then(function (response) {
        console.log(response);
        if (response) {
          if (settings?.multi_store === "0" || settings?.multi_store === 0) {
            localStorage.setItem(
              "location",
              JSON.stringify(settings?.default_store)
            );
          }
          localStorage.setItem("wAccessToken", response.access_token);
          localStorage.setItem("wRefreshToken", response?.refresh_token);
          let now = (Date.now() / 1000) | 0;
          localStorage.setItem("wExpiresIn", now + response.expires_in);
          props.setSigninvisible(false);
          getUserData();
        }
        setBtnLoading(false);
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function getUserData() {
    ApiService({
      method: "GET",
      route: "c/me",
    }).then((response) => {
      console.log(response);
      if (response.data) {
        setBtnLoading(false);
        setSettings(response.data.data || {});
        props.setSigninvisible(false);
        // window.location.reload(false);
      }
    });
  }

  if (props.signinvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        &nbsp;&nbsp;{" "}
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setSigninvisible(false)}
        />
        Sign In
      </div>

      {settings?.web?.web_logo ? (
        <img
          src={ImgUrl("web") + "/" + settings?.web?.web_logo}
          className="header-logo"
          alt={settings?.project_name}
        />
      ) : (
        <GText
          g2="true"
          bold
          theme
          text={"LOGO"}
          style={{ margin: "15px 30px 10px", width: "auto" }}
        />
      )}

      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ isValid }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Email or Phone"
                name="username"
                // className="noBorder"
              />

              <FormikControl
                control="input"
                type="password"
                label="Password"
                name="password"
              />

              <span
                style={{
                  margin: "0 15px 25px",
                  float: "right",
                  color: ({ theme }) => theme.body,
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setFpvisible(true);
                  props.setSigninvisible(false);
                }}
              >
                Forgot Password?
              </span>
              <br />
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "0 15px",
                  width: "calc(100% - 30px)",
                }}
                children="SIGN IN"
                type="submit"
                loading={isBtnLoading}
              />
              <br />
            </Form>
          )}
        </Formik>

        <GButton
          variant="linkable"
          style={{
            margin: "15px",
            width: "95%",
          }}
          children="Create an Account"
          onClick={() => {
            props.setSignupvisible(true);
            props.setSigninvisible(false);
          }}
          type="button"
        />
      </div>
    </React.Fragment>
  );
}
