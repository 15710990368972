import React, { useState } from "react";
import { ImgUrl } from "../services";
import GView from "../Elements/GView";
import GText from "../Elements/GText";
import GInput from "../Elements/gInput";
import GSelectbox from "../Elements/gSelectbox";
import styled from "styled-components";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as UnCheck } from "../assets/images/untick.svg";
import GImageEditor from "../Elements/gFormImageEditor";
import GDocEditor from "../Elements/gFormDocEditor";

const DIV = styled.div`
  padding: 0 20px 15px;
  border-bottom: 1px solid #e2e2e2;
  & .inputDiv {
    display: flex;
    padding: 2px 0;
    margin: 10px 0;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      &.checked {
        path {
          fill: ${({ theme }) => theme.body};
        }
      }
    }
    label {
      font-size: 14px;
      color: #333;
      cursor: pointer;
    }
  }
`;

const OrderInstructions = (props) => {
  const [fieldsData, setFieldsData] = useState(props.data || []);
  const [selectedVal, setSelectedVal] = useState(
    props.orderData?.special_instructions || ""
  );

  return (
    <GView style={{ background: "#fff", padding: "10px 10px 0" }}>
      <GText
        semi
        text={"Select any special instructions for the delivery agent"}
        style={{
          // fontStyle: "italic",
          margin: "10px 12px 15px",
          fontSize: "14.5px",
          color: "#333",
        }}
      />

      <DIV>
        {fieldsData.map((o, ind) => {
          return (
            <GView
              className="inputDiv"
              onClick={() => {
                setSelectedVal(o.value);
                let nData = { ...props.orderData };
                nData.special_instructions = o.value;
                props.setOrderData({ ...nData });
              }}
              key={ind}
            >
              <input
                name={"sInstructions"}
                type="radio"
                id={"vs_option_" + ind}
                value={o}
                defaultChecked={selectedVal == o.value}
                style={{ display: "none" }}
              />
              {selectedVal === o.value ? (
                <Check className="checked" />
              ) : (
                <UnCheck />
              )}
              &nbsp;&nbsp;
              <label htmlFor={"vs_option_" + ind} style={{}}>
                {o.title}
              </label>
            </GView>
          );
        })}
      </DIV>
    </GView>
  );
};

export default OrderInstructions;
