import React, { useState } from "react";
import { ApiService } from "../services";
import CardForm from "../forms/cardForm";
import GButton from "../Elements/GButton";
import { useSnackbar } from "notistack";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import styled from "styled-components";
import GConfirmDialog from "../Elements/gConfirmDialog";
import GActions from "../Elements/gActionsStyle";
import useWindowSize from "../DataFactory/useWindowSize";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import Uncheck from "../assets/images/untick.png";

const ListCard = styled.div`
  padding: 0.9em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  background: #fff;
  vertical-align: top;
  border: 1px solid #f2f2f2;
  margin: 5px;
  height: auto;
  overflow: overlay;

  label {
    display: flex;

    img {
      margin-top: 5px;
    }
  }

  span {
    cursor: pointer;
    svg {
      width: 25px;
      height: 25px;
      margin-top: 5px;
      path {
        fill: ${({ theme }) => theme.body};
      }
    }
  }
`;

export default function CardsList() {
  const { width } = useWindowSize();
  const [cardFvisible, setCardFvisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(false);
  const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("cards", false);

  const handleDelete = () => {
    ApiService({
      method: "DELETE",
      route: "c/cards/" + selectedCardId,
      body: "",
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDefaultCard = (newVal) => {
    ApiService({
      method: "PUT",
      route: "c/cards/" + selectedCardId,
      body: { is_default: 1 },
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <GView
        style={{ display: "flex", margin: "10px 5px", alignItems: "center" }}
      >
        <GText g2="true" theme bold text={"Payment Info"} />
        {data.length > 0 && (
          <>
            &nbsp;
            <GText
              g5
              med
              semi
              text={" (" + data.length + " Cards)"}
              style={{ fontStyle: "italic" }}
            />
          </>
        )}
        <GButton
          variant="condensed"
          children="Add New"
          onClick={() => {
            setSelectedCard({});
            setIsEmpty(true);
            setCardFvisible(true);
          }}
          style={{
            marginLeft: "auto",
          }}
        />
      </GView>

      {loading === true ? (
        <Shimmer></Shimmer>
      ) : data.length === 0 ? (
        <Placeholder />
      ) : (
        <React.Fragment>
          <GView
            style={{
              display: data.length == 1 && width > 767 ? "inline-grid" : "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gridGap: "10px",
            }}
          >
            {data.map((v, i) => {
              return (
                <ListCard
                  key={i}
                  style={{
                    border: "1px solid #f2f2f2",
                    padding: "0.9rem",
                    overflow: "initial",
                  }}
                >
                  <div
                    className="radio"
                    style={{
                      cursor: "pointer",
                      verticalAlign: "top",
                    }}
                  >
                    <label>
                      <input
                        type="radio"
                        value="cash"
                        checked={v.is_default === 1}
                        onChange={() => {
                          console.log(v.id);
                        }}
                        style={{
                          cursor: "pointer",
                          height: "17px",
                          width: "17px",
                          display: "none",
                        }}
                      />

                      {v.is_default === 1 ? (
                        <span className="active">
                          <Check style={{ fontSize: "1rem" }} />
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            setSelectedCardId(v.id);
                            setDefaultDialogOpen(true);
                          }}
                        >
                          <img src={Uncheck} style={{ width: "25px" }} alt="" />
                        </span>
                      )}

                      <div
                        onClick={() => {
                          setSelectedCardId(v.id);
                          setDefaultDialogOpen(true);
                        }}
                        style={{
                          marginLeft: "20px",
                          display: "inline-block",
                          width: "calc(100% - 90px)",
                        }}
                      >
                        <GText
                          g4
                          semi
                          dark
                          text={v.card_type}
                          style={{
                            textTransform: "uppercase",
                            fontSize: "17px",
                          }}
                        />
                        <GText
                          dark
                          text={`•••• •••• •••• ${v.last_4_digits}`}
                          style={{ fontSize: "16px", paddingTop: "5px" }}
                        />
                      </div>
                      <div
                        style={{
                          color: "#555",
                          fontWeight: "400",
                          display: "inline-block",
                          width: "40px",
                          minHeight: "75px",
                          textAlign: "right",
                        }}
                      >
                        <GActions
                          // onEdit={() => {
                          //   setSelectedCard(v);
                          //   setIsEmpty(false);
                          //   setCardFvisible(true);
                          // }}
                          onDelete={() => {
                            setSelectedCardId(v.id);
                            setDialogOpen(true);
                          }}
                          icons={true}
                          style={{ position: "relative" }}
                          menuStyle={{ fontSize: "15px" }}
                        />
                      </div>
                    </label>
                  </div>
                </ListCard>
              );
            })}
          </GView>
        </React.Fragment>
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Card"
          text="Are you sure you want to delete this card?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleDelete();
          }}
        ></GConfirmDialog>
      )}

      {defaultDialogOpen === true && (
        <GConfirmDialog
          open={defaultDialogOpen}
          title="Set Default Card"
          text="Are you sure you want to set this as a default card?"
          handleClose={() => setDefaultDialogOpen(false)}
          onConfirmClose={() => {
            setDefaultDialogOpen(false);
            handleDefaultCard();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={cardFvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCardFvisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
            paddingBottom: "0",
          },
        }}
        contentLabel="Card Modal"
      >
        <CardForm
          cardFvisible={cardFvisible}
          setCardFvisible={setCardFvisible}
          data={selectedCard}
          onSubmit={refreshData}
          isEmpty={isEmpty}
        />
      </Modal>
    </React.Fragment>
  );
}
