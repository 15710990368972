import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ApiService } from "../services";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as Uncheck } from "../assets/images/untick.svg";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import GButton from "../Elements/GButton";
import { useSnackbar } from "notistack";
import AddCreditCard from "./addCard";
import GText from "../Elements/GText";
import GConfirmDialog from "../Elements/gConfirmDialog";

const CardDiv = styled.div`
  padding: 15px 10px;
  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 10px 0;
    li {
      border: 1px solid #e2e2e2;
      padding: 5px 10px;
      border-radius: 4px;
      margin: 10px 0;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      span {
        svg {
          height: 20px;
          width: 20px;
        }
      }
      span.active svg {
        path {
          fill: ${({ theme }) => theme.body};
        }
      }
      label {
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        color: #555;
        margin: 0;
        svg {
          font-size: 8px;
          color: #ccc;
          padding: 0 1px;
        }
      }
    }
  }
`;

export default function CardPayment(props) {
  const [selectedCard, setSelectedCard] = useState({});
  const [cardPayment, setCardPayment] = useState(
    props.paymentGateways?.card || {}
  );
  const [isAddCard, setAddCard] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const deleteCreditCard = () => {
    ApiService({ method: "DELETE", route: "c/cards/" + selectedCardId })
      .then((response) => {
        if (response.data.status_code === 1) {
          console.log(response.data);
          let newData = cardPayment?.options.filter(
            (c) => c.id !== selectedCardId
          );
          cardPayment.options = newData;
          setCardPayment(cardPayment);
          return;
        } else {
          enqueueSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!props.cardPayment?.card_id) {
      setSelectedCard({});
      setSelectedCardId("");
    }
  }, [props.cardPayment]);
  return (
    <CardDiv>
      <GText text={"Choose Credit/Debit Card"} />
      <ul>
        {cardPayment?.options &&
          cardPayment?.options?.map((c, i) => {
            return (
              <div style={{ display: "flex" }} key={i}>
                <li
                  onClick={() => {
                    setSelectedCard(c);
                    props.onSelecteCard(c);
                  }}
                >
                  <input
                    name="option_card_payment"
                    type="radio"
                    id={"v_option_" + c.id}
                    value={c.id}
                    style={{ display: "none" }}
                    defaultChecked={selectedCard?.id === c.id}
                  />
                  {selectedCard?.id === c.id ? (
                    <span className="active">
                      <Check style={{ fontSize: "1rem" }} />
                    </span>
                  ) : (
                    <span>
                      <Uncheck style={{ fontSize: "1rem" }} />
                    </span>
                  )}
                  &nbsp;&nbsp;
                  <label htmlFor={"v_option_" + c.id}>
                    •••• &nbsp;&nbsp; •••• &nbsp;&nbsp; ••• &nbsp;&nbsp;
                    {c.last_4_digits}
                  </label>
                </li>
                <GButton
                  variant="linkable"
                  onClick={() => {
                    setSelectedCardId(c.id);
                    setDialogOpen(true);
                  }}
                  style={{ padding: "1px" }}
                >
                  <Delete style={{ float: "right" }} />
                </GButton>
              </div>
            );
          })}
      </ul>

      {isAddCard === true ? (
        <React.Fragment>
          <hr style={{ margin: "20px 0", border: "1px dashed #e2e2e2" }} />
          <AddCreditCard
            cardType={cardPayment.identifier}
            setAddCard={setAddCard}
            onCardAdd={(card) => {
              setAddCard(false);
              cardPayment.options.push(card);
              setCardPayment(cardPayment);
              setSelectedCard(card);
              props.onSelecteCard(card);
            }}
            isEmpty={true}
          />
        </React.Fragment>
      ) : (
        <GButton
          variant="linkable"
          onClick={() => {
            setAddCard(true);
          }}
        >
          + Add Card
        </GButton>
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete"
          text="Are you sure you want to delete this card?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCreditCard();
          }}
        ></GConfirmDialog>
      )}
    </CardDiv>
  );
}
