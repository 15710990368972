import React, { Component } from "react";
import styled from "styled-components";
import { ApiService } from "../services";

const Input = styled.input`
  width: calc(100% - 60px);
  padding: 10px;
  margin: 0 20px;
  border: 1px solid #ddd;
  outline: 0;
`;

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  componentDidMount({ map, mapApi } = this.props) {
    const options = {
      // restrict your search to a specific type of result
      // types: ['geocode', 'address', 'establishment', '(regions)', '(cities)'],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    this.autoComplete = new mapApi.places.Autocomplete(
      this.searchInput,
      options
    );
    this.autoComplete.addListener("place_changed", this.onPlaceChanged);
    this.autoComplete.bindTo("bounds", map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  getRequestAddress(location, data) {
    ApiService({
      method: "POST",
      route: "c/format-address",
      body: {
        geometry: {
          location: location,
        },
        address_components: data.address_components,
        name: data?.name || "",
        vicinity: data?.vicinity || "",
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          this.props.onPlacesChanged(response.data.data);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onPlaceChanged = ({ map, addplace } = this.props) => {
    const place = this.autoComplete.getPlace();

    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    console.log(place);
    this.searchInput.blur();

    this.getRequestAddress(place.geometry?.location, place);

    // var addressArray = place.address_components;
    // var city = this.getCity(addressArray);
    // var area = this.getArea(addressArray);
    // var state = this.getState(addressArray);
    // var country = this.getCountry(addressArray);
    // var zipcode = this.getZipcode(addressArray);

    // var adreessComponents = {
    //   address: place.formatted_address,
    //   area: area ? area : "",
    //   city: city ? city : "",
    //   state: state ? state : "",
    //   country: country ? country : "",
    //   zipcode: zipcode ? zipcode : "",
    //   title:place?.name || ""
    // };

    // console.log(adreessComponents);
  };

  clearSearchBox() {
    this.searchInput.value = "";
  }

  render() {
    return (
      <Input
        ref={(ref) => {
          this.searchInput = ref;
        }}
        type="text"
        onFocus={this.clearSearchBox}
        placeholder={"Enter Area, Street or Landmark.."}
      />
    );
  }
}

export default AutoComplete;
