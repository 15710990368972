import React, { useState, useEffect } from "react";
import GMap from "./orderLocation";
import { ReactComponent as PhoneIcon } from "../assets/images/phone.svg";
import { ReactComponent as ChatIcon } from "../assets/images/chat.svg";
import { ImgUrl, addDefaultSrc } from "../services";
import GText from "../Elements/GText";

const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

const App = (props) => {
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  return (
    <div>
      {!loadMap ? (
        <div>Loading...</div>
      ) : (
        <GMap map={true} data={props.taskData} />
      )}
      <div style={{ padding: "18px 0 8px", display: "flex" }}>
        <div>
          <h4 style={{ margin: "5px 0", fontWeight: "500", fontSize: "15px" }}>
            {props.status?.title}{" "}
            <span
              style={{
                background: "#" + props.status?.color1,
                height: "8px",
                width: "8px",
                display: "inline-block",
                marginLeft: "3px",
                borderRadius: "20px",
              }}
            ></span>
          </h4>
          <GText
            g6
            text={
              "Delivery Agent : " + (props.agent?.title || "Not Assigned yet")
            }
          />
        </div>
        {props.agent?.id && (
          <div
            style={{
              marginLeft: "auto",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <img
              src={ImgUrl("user") + "/" + props.agent?.thumb_photo}
              alt={props.agent?.title}
              onError={(e) => addDefaultSrc(e, "user")}
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "20px",
              }}
              width="40px"
              height="40px"
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"tel:" + props.agent?.phone}
              style={{
                cursor: "pointer",
                margin: "0 5px",
                display: "inherit",
              }}
            >
              {" "}
              <PhoneIcon
                style={{
                  width: "32px",
                  height: "32px",
                  // marginTop: "20px",
                  // // position: "absolute",
                  // right: "-10px",
                  // bottom: "-5px",
                }}
              />
            </a>

            <ChatIcon
              onClick={() => props.setOmvisible(true)}
              style={{ width: "32px", height: "32px", cursor: "pointer" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
