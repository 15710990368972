import React, { useState } from "react";
import { ApiLoginService } from "../services";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

export default function VerifySignupOtp(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);

  const initialValues = {
    otp: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);

    if (props.type === "signup") {
      values.first_name = props.data?.first_name;
      values.last_name = props.data?.last_name;
      values.email = props.data.email;
      values.phone = props.data.phone;
      values.password = props.data.password;
      values.account_type = props.data.account_type;
      values.business_name =
        props.data.account_type == "business" ? props.data.business_name : "";
      ApiLoginService("POST", "c/signup", values).then((response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 1) {
          props.onVerify();
          props.setVerifyOtpvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.message);
        return;
      });
    } else {
      values.username = props.data.username;

      ApiLoginService("POST", "c/verify-otp", values).then((response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 1) {
          props.onVerify(values.otp);
          props.setVerifyOtpvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.message);
        return;
      });
    }
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required("Required"),
  });

  if (props.verifyOtpvisible === false) {
    return null;
  }
  return (
    <div className="modal-body">
      <h4 style={{ margin: "5px 10px" }}>Enter OTP</h4>
      <p
        style={{
          fontSize: "14px",
          margin: "10px",
          lineHeight: "20px",
          color: "#333",
        }}
      >
        Confirm One Time Pin Sent to Email and Mobile Number
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => (
          <Form>
            <FormikControl
              control="input"
              type="text"
              placeholder="Type OTP..."
              name="otp"
              style={{ height: "45px" }}
            />
            <br />
            <GButton
              variant="condensed"
              disabled={!isValid}
              style={{
                margin: "0 15px",
                width: "92%",
              }}
              children="VERIFY"
              type="submit"
              loading={isBtnLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
