import React, { useContext } from "react";
import styled from "styled-components";
import OrdersIcon from "../assets/images/orders.png";
import AddressIcon from "../assets/images/address.png";
import WalletIcon from "../assets/images/wallet.png";
import CardIcon from "../assets/images/cards.png";
import InviteIcon from "../assets/images/invite.png";
import SettingIcon from "../assets/images/settings.svg";
import ApiTokenIcon from "../assets/images/apiKey.png";
import { Link } from "react-router-dom";
import useWindowSize from "../DataFactory/useWindowSize";
import { SettingsContext } from "../DataFactory/useSettings";

export const Plink = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 200px;
  margin-right: 20px;
  vertical-align: top;
  display: flex;

  @media (max-width: 767px) {
    width: 100%;
    overflow: overlay;
  }

  li {
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 10px 5px;
      color: #555;
      font-size: 15px;
      font-weight: 500;

      img {
        opacity: 0.4;
      }

      &.active {
        color: ${({ theme }) => theme.text};
        background: #f4f5f9;
        img {
          opacity: 1;
        }
      }
      @media (max-width: 767px) {
        text-align: center;
        font-size: 13px;
      }
    }
    @media (max-width: 767px) {
      width: 17.5%;
      min-width: 55px;
    }
  }
`;

export default function ProfileLinks(props) {
  const { settings } = useContext(SettingsContext);
  const { width } = useWindowSize();

  const ProfileRoutes = [
    {
      title: "Orders",
      icon: OrdersIcon,
      link: "orders",
      match_url: "/profile/orders",
      isHide: false,
    },
    {
      title: "Address Book",
      icon: AddressIcon,
      link: "address",
      match_url: "/profile/address",
      isHide: false,
    },
    {
      title: "delivAIry Points",
      icon: WalletIcon,
      link: "wallet",
      match_url: "/profile/wallet",
      isHide: false,
    },
    {
      title: "Payment Info",
      icon: CardIcon,
      link: "cards",
      match_url: "/profile/cards",
      isHide: settings?.card?.identifier ? false : true,
    },
    {
      title: "Invite & Earn",
      icon: InviteIcon,
      link: "invite",
      match_url: "/profile/invite",
      isHide: false,
    },
    {
      title: "Default Instructions",
      icon: SettingIcon,
      link: "settings",
      match_url: "/profile/settings",
      isHide: false,
    },
    {
      title: "API Tokens",
      icon: ApiTokenIcon,
      link: "api-tokens",
      match_url: "/profile/api-tokens",
      isHide: settings?.user?.rest_active == "1" ? false : true,
    },
  ];
  return (
    <Plink style={{ flexDirection: width <= 767 ? "row" : "column" }}>
      {ProfileRoutes.filter((f) => f.isHide == false).map((route, i) => {
        return (
          <li key={i}>
            <Link
              to={route.link}
              state={{ title: route.title }}
              className={props.url === route.match_url ? "active" : ""}
              style={{ flexDirection: width <= 767 ? "column" : "row" }}
            >
              <img
                src={route.icon}
                alt="orders"
                style={{
                  width: "19px",
                  marginRight: width <= 767 ? "0" : "10px",
                  marginBottom: width <= 767 ? "10px" : "0",
                }}
              />
              {route.title}
            </Link>
          </li>
        );
      })}
    </Plink>
  );
}
