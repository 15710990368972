import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useWindowSize from "../DataFactory/useWindowSize";
import { ReactComponent as CheckIcon } from "../assets/images/tick.svg";

export const Plink = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0px;
  vertical-align: top;
  display: flex;
  width: 100%;
  overflow: overlay;
  background: #fff;
  border-bottom: 1px solid #e2e2e2;

  li {
    width: 25%;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 12px 15px;
      color: #555;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      position: relative;

      &::before {
        content: "";
        border: 2px solid #e2e2e2;
        width: 65%;
        top: 22px;
        left: 65%;
        position: absolute;
      }

      &.active {
        // background: #fff;
        color: #333;

        &::before {
          content: "";
          border: 2px solid ${({ theme }) => theme.body};
          width: 65%;
          top: 22px;
          left: 65%;
          position: absolute;
        }
      }

      svg {
        height: 30px;
        width: 30px;
        fill: ${({ theme }) => theme.body};
      }
      span {
        height: 18px;
        display: inline-block;
        width: 18px;
        padding: 1px 2px 2px 1px;
        border-radius: 20px;
        border: 1px solid #e2e2e2;
        color: #a2a2a2;
        background: #e2e2e2;
        z-index: 1;
        &.active {
          border: 1px solid ${({ theme }) => theme.body};
          background: ${({ theme }) => theme.body};
          color: #fff;
        }
      }
    }
    @media (max-width: 767px) {
      width: 25%;
      min-width: 75px;
    }

    &:last-child a::before {
      border: 0;
    }
  }
`;

export default function GStepRoutes(props) {
  console.log(props);
  const { routes } = props;
  const { width } = useWindowSize();
  return (
    <Plink>
      {routes.map((route, i) => {
        return (
          <li key={i} style={{ width: 100 / routes.length + "%" }}>
            {route.link ? (
              <a
                // to={route.link}
                className={props.url === route.match_url ? "active" : ""}
                style={{ flexDirection: width <= 767 ? "column" : "row" }}
              >
                {routes.findIndex((r) => props.url === r.match_url) > i ? (
                  <CheckIcon />
                ) : (
                  <span
                    className={
                      routes.findIndex((r) => props.url === r.match_url) == i
                        ? "active"
                        : ""
                    }
                  >
                    {route.index}
                  </span>
                )}
                &nbsp;
                {route.title}
              </a>
            ) : (
              <a
                onClick={(e) => {
                  if (props.onSelectTab) {
                    props.onSelectTab(route);
                  }
                }}
                className={route.index <= props.activeIndex ? "active" : ""}
              >
                {/* {props.activeIndex > route.index ? (
                  <CheckIcon />
                ) : ( */}
                <span
                  className={props.activeIndex >= route.index ? "active" : ""}
                >
                  {route.index}
                </span>
                {/* )} */}
                &nbsp;
                {route.title}
              </a>
            )}
          </li>
        );
      })}
    </Plink>
  );
}
