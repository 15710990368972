import react from "react";
import Echo from "laravel-echo";

export function createSocketConnection() {
  window.Pusher = require("pusher-js");

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "anyKey",
    wsHost: process.env.REACT_APP_WS_HOST,
    wsPort: process.env.REACT_APP_WS_PORT,
    wssPort: process.env.REACT_APP_WS_PORT,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    scheme: process.env.REACT_APP_MODE == "production" ? "https" : "http",
    forceTLS: process.env.REACT_APP_MODE == "production" ? true : false,
    auth: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    },
  });
}
