import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { useSnackbar } from "notistack";
import { GImagePicker } from "../Elements/gImagePicker";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";

export default function EditProfile(props) {
  const { settings, setSettings } = useContext(SettingsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);
  console.log(settings.user);
  const initialValues = {
    first_name: settings?.user?.first_name || "",
    last_name: settings?.user?.last_name || "",
    email: settings?.user?.email || "",
    photo: settings?.user?.photo || "",
    thumb_photo: settings?.user?.thumb_photo || "",
    phone: settings?.user?.phone || "",
    account_type: settings?.user?.account_type || "",
    business_name: settings?.user?.business_name || "",
    pickup_notes: settings?.user?.pickup_notes || "",
    delivery_notes: settings?.user?.delivery_notes || "",
  };
  const { enqueueSnackbar } = useSnackbar();
  const [icon, setIcon] = useState("");

  const onSubmit = (values) => {
    setBtnLoading(true);
    let sendData = { ...values };
    if (!sendData.password) {
      delete sendData.password;
    }
    // sendData.business_name =
    //   sendData.account_type == "business" ? sendData.business_name : "";
    console.log(sendData);

    ApiService({ method: "PUT", route: "c/me", body: sendData }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          settings.user = response.data.data;
          setSettings({ ...settings });
          // localStorage.setItem("settings", JSON.stringify(settings));
          props.setEpvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required("Required")
      .min("2", "Name should be atleast 2 characters"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
  });

  if (props.epvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setEpvisible(false)}
        />
        Edit Profile
      </div>
      <div className="modal-body" style={{ paddingBottom: "10px" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <div style={{ margin: "0 15px", maxWidth: "300px" }}>
                <GImagePicker
                  type="user"
                  alt={settings?.user?.first_name}
                  height={100}
                  width={100}
                  radius={50}
                  editor
                  editorProps={{
                    height: 200,
                    width: 200,
                    radius: 100,
                  }}
                  serverProps={{
                    size: 200,
                  }}
                  setBtnLoading={setBtnLoading}
                  onSuccess={(v) => {
                    console.log(v);
                    setIcon(v.image);
                    values.photo = v.image;
                    values.thumb_photo = v.thumb_image;
                  }}
                  isPreviewImg={true}
                  default={ImgUrl("user") + "/" + settings?.user?.photo}
                />
              </div>
              <br />
              {settings?.business_accounts == 1 && (
                <>
                  <FormikControl
                    control="radio"
                    label="Account Type"
                    name="account_type"
                    options={[
                      { title: "Personal", value: "personal" },
                      { title: "Business", value: "business" },
                    ]}
                    key_title="title"
                    key_value="value"
                    selected={values.account_type}
                    custom={true}
                    divstyle={{ width: "40%" }}
                    labelStyle={{ cursor: "pointer" }}
                  />

                  {values.account_type == "business" && (
                    <FormikControl
                      control="input"
                      type="text"
                      label="Business name"
                      name="business_name"
                    />
                  )}
                </>
              )}

              <FormikControl
                control="input"
                type="text"
                label="First name"
                name="first_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Last name"
                name="last_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label="Email ID"
                name="email"
                disabled={true}
              />
              <FormikControl
                control="input"
                type="text"
                label="Phone"
                name="phone"
                disabled={true}
              />
              <br />
              <GButton
                variant="condensed"
                children="Update"
                style={{ margin: "0 15px 10px", width: "92%" }}
                type="submit"
                loading={isBtnLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
