import React, { useState, useContext } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
// import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ApiLoginService, ImgUrl } from "../services";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import VerifyOtpForm from "./verifyOtp";
import GText from "../Elements/GText";
import Modal from "react-modal";
import { SettingsContext } from "../DataFactory/useSettings";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Signup(props) {
  const { settings, refreshSettings } = useContext(SettingsContext);
  const [verifyOtpvisible, setVerifyOtpvisible] = useState(false);
  const [signupData, setSignupData] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    email: "",
    phone: "",
    password: "",
    account_type: "",
    business_name: "",
    terms: "",
  };

  useEffect(() => {
    refreshSettings();
  }, []);
  const onSubmit = (values) => {
    setBtnLoading(true);
    let sendData = { ...values };
    sendData.business_name =
      sendData.account_type == "business" ? sendData.business_name : "";
    console.log(sendData);

    ApiLoginService("POST", "c/signup/otp", sendData).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        setSignupData(values);
        setVerifyOtpvisible(true);
      }
      setBtnLoading(false);
      enqueueSnackbar(response.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid Email Address").required("Required"),
    phone: Yup.string()
      .matches(
        /^([+ ]*)([\d{1,2}]*)(\d{5}([- ]*)\d{5})$/g,
        "Invalid phone number"
      )
      .min(9, "Min length is 10")
      .max(14, "Too Long!")
      .required("Required"),
    password: Yup.string()
      .required("Required")
      .min(6, "Password is too short - should be 6 chars minimum"),
    terms: Yup.string().required("Required"),
  });

  const getOauthToken = () => {
    var refresh_token_data = {
      username: signupData.email,
      password: signupData.password,
      grant_type: "password",
      client_id: 2,
      client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
      provider: "customers",
    };
    console.log(refresh_token_data);

    ApiLoginService("POST", "oauth/token", refresh_token_data)
      .then(function (response) {
        if (response) {
          console.log(response);
          localStorage.setItem("wAccessToken", response.access_token);
          localStorage.setItem("wRefreshToken", response?.refresh_token);
          let now = (Date.now() / 1000) | 0;
          localStorage.setItem("wExpiresIn", now + response.expires_in);
          props.setSignupvisible(false);
          window.location.href = "/profile/settings";
          // window.location.reload(false);
        } else {
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onHandleVerify = () => {
    getOauthToken();
  };

  if (props.signupvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        &nbsp;&nbsp;
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setSignupvisible(false)}
        />
        Sign Up
      </div>

      {props.settings?.web?.web_logo ? (
        <img
          src={ImgUrl("web") + "/" + props.settings?.web?.web_logo}
          className="header-logo"
          alt={props.settings?.project_name}
        />
      ) : (
        <GText
          g2
          bold
          theme
          text={"LOGO"}
          style={{ margin: "15px 30px 10px", width: "auto" }}
        />
      )}

      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ isValid, setFieldValue, values }) => (
            <Form>
              {settings?.business_accounts == 1 && (
                <>
                  <FormikControl
                    control="radio"
                    label="Account Type"
                    name="account_type"
                    options={[
                      { title: "Personal", value: "personal" },
                      { title: "Business", value: "business" },
                    ]}
                    key_title="title"
                    key_value="value"
                    selected={values.account_type}
                    custom={true}
                    divstyle={{ width: "40%" }}
                    labelStyle={{ cursor: "pointer" }}
                  />

                  {values.account_type == "business" && (
                    <FormikControl
                      control="input"
                      type="text"
                      label="Business name*"
                      name="business_name"
                    />
                  )}
                </>
              )}

              <FormikControl
                control="input"
                type="text"
                label="First name"
                name="first_name"
              />

              <FormikControl
                control="input"
                type="text"
                label="Last name"
                name="last_name"
              />

              <FormikControl
                control="input"
                type="email"
                label="Email ID*"
                name="email"
                // className="noBorder"
              />
              <FormikControl
                control="numberInput"
                type="text"
                label="Phone*"
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9.+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
              <span style={{ display: "block", position: "relative" }}>
                <FormikControl
                  control="input"
                  type={isVisible === true ? "text" : "password"}
                  label="Password*"
                  name="password"
                />
                <span
                  style={{ position: "absolute", right: "30px", top: "45px" }}
                >
                  {/* {isVisible === false ? (
                    <VisibilityOff
                      onClick={() => setIsVisible(true)}
                      style={{ fontSize: "20px", fill: "#a2a2a2" }}
                    />
                  ) : (
                    <Visibility
                      onClick={() => setIsVisible(false)}
                      style={{ fontSize: "20px", fill: "#a2a2a2" }}
                    />
                  )} */}
                </span>
              </span>

              <span
                style={{
                  position: "relative",
                  display: "flex",
                  padding: "5px 16px",
                }}
              >
                <input
                  type="checkbox"
                  id="Terms"
                  name="terms"
                  value={checked}
                  checked={checked}
                  onChange={() => {
                    console.log(!checked == true ? "agree" : "");
                    setChecked(!checked);
                    setFieldValue("terms", !checked == true ? "agree" : "");
                  }}
                  style={{ marginRight: "8px" }}
                ></input>
                <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                  By creating an account, I accept the{" "}
                  <Link
                    to="/terms"
                    target="_blank"
                    style={{ color: "#337ab7", textDecoration: "none" }}
                  >
                    Terms & Conditions
                  </Link>
                  &nbsp;and&nbsp;
                  <Link
                    to="/privacy-policy"
                    target="_blank"
                    style={{ color: "#337ab7", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </Link>
                </span>
              </span>
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "15px",
                  width: "calc(100% - 30px)",
                }}
                children="SIGN UP"
                type="submit"
                loading={isBtnLoading}
              />
            </Form>
          )}
        </Formik>

        <GButton
          variant="linkable"
          style={{
            margin: "15px",
            width: "calc(100% - 30px)",
          }}
          children="Login to your Account"
          onClick={() => {
            props.setSigninvisible(true);
            props.setSignupvisible(false);
          }}
          type="button"
        />
      </div>

      <Modal
        isOpen={verifyOtpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setVerifyOtpvisible(false)}
        style={{
          content: {
            width: "350px",
            top: "0%",
            left: "0%",
            right: "0",
            bottom: "0",
            margin: "auto",
            height: "270px",
            // transform: 'translate(0%, -50%)'
          },
        }}
        contentLabel="Verify OTP Modal"
      >
        <VerifyOtpForm
          verifyOtpvisible={verifyOtpvisible}
          setVerifyOtpvisible={setVerifyOtpvisible}
          type="signup"
          data={signupData}
          onVerify={() => onHandleVerify()}
        />
      </Modal>
    </React.Fragment>
  );
}
