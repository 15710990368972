import React, { useState, useEffect } from "react";
import GView from "../Elements/GView";
import GText from "../Elements/GText";
import styled from "styled-components";
import useWindowSize from "../DataFactory/useWindowSize";
import GButton from "../Elements/GButton";
import GActions from "../Elements/gActionsStyle";
import GInput from "../Elements/gInput";
import { GSelectCheckbox } from "../Elements/gSelectCheckbox";
import { useSnackbar } from "notistack";
import { ReactComponent as Close } from "../assets/images/cancel.svg";

const ItemDiv = styled.div`
  position: relative;
  display: flex;
  margin: 10px 0;
  font-size: 15px;
  line-height: 18px;
  padding: 12px;
  select,
  input {
    color: #999;
    &.filled {
      color: ${({ theme }) => theme.body}!important;
    }
  }
`;

const OrderItems = (props) => {
  console.log(props.data);
  const { width } = useWindowSize();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [itemArray, setItemArray] = useState(props.data || []);
  const { categories, containers, fits, weights } = props.data;
  const [addedItems, setAddedItems] = useState(
    props.orderData?.items.length > 0
      ? props.orderData.items
      : [
          {
            category: "",
            packaging: "",
            fit: "",
            weight: "",
            quantity: "",
            notes: "",
          },
        ]
  );

  const action = (snackbarId) => (
    <>
      <GButton
        variant="linkable"
        style={{ color: "#fff" }}
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <Close style={{ fill: "#fff", width: "20px", height: "20px" }} />
      </GButton>
    </>
  );

  let options = {
    persist: true,
    variant: "error",
    action,
  };

  return (
    <GView style={{ padding: "15px 0" }}>
      <GText g4 semi text={"Item Details"} style={{ marginBottom: "10px" }} />

      {props.data && (
        <>
          {addedItems.map((item, ind) => {
            return (
              <ItemDiv
                key={ind}
                style={{
                  background: "#fff",
                  padding: "15px",
                  border: "1px solid #e2e2e2",
                }}
              >
                <GView style={{ width: "calc(100% - 35px)" }}>
                  I am sending{" "}
                  <GSelectCheckbox
                    data={categories}
                    title={"Category"}
                    value={item.category}
                    option_key="title"
                    option_value="id"
                    handleChange={(v) => {
                      console.log(v);
                      item.category = v || "";
                      addedItems[ind].category = v || "";
                      setAddedItems([...addedItems]);
                      props.onChange(addedItems);
                    }}
                    style={{ minWidth: "auto", width: "auto" }}
                  />{" "}
                  packed in
                  <GInput
                    type="text"
                    display={item.quantity ? "filled" : ""}
                    name={"quantity_" + ind}
                    id={`quantity_filter_${ind}`}
                    value={item.quantity}
                    placeholder="Quantity"
                    maxLength={5}
                    style={{
                      background: "#fff",
                      borderBottom: "1px solid #e2e2e2",
                      padding: "2px",
                      width: item.quantity ? "40px" : "55px",
                      textAlign: "center",
                    }}
                    mainClass={"inline"}
                    onChange={(e) => {
                      const re = /^[0-9. \b]+$/;
                      if (e.target.value && !re.test(e.target.value)) {
                        return;
                      } else {
                        item.quantity = e.target.value || "";
                        addedItems[ind].quantity = e.target.value || "";
                        setAddedItems([...addedItems]);
                        props.onChange(addedItems);
                      }
                    }}
                  />
                  <GSelectCheckbox
                    data={containers}
                    title={"Packaging"}
                    value={item.packaging}
                    option_key="title"
                    option_value="id"
                    handleChange={(v) => {
                      console.log(v);
                      item.packaging = v || "";
                      addedItems[ind].packaging = v || "";
                      setAddedItems([...addedItems]);
                      props.onChange(addedItems);
                    }}
                    style={{ minWidth: "auto", width: "auto" }}
                  />
                  .<br /> The total order will{" "}
                  <GSelectCheckbox
                    data={fits}
                    title={"Fit In"}
                    value={item.fit}
                    option_key="title"
                    option_value="id"
                    handleChange={(v, vData) => {
                      console.log(v);
                      item.fit = v || "";
                      addedItems[ind].fit = v || "";
                      setAddedItems([...addedItems]);
                      props.onChange(addedItems);
                      if (vData?.vehicle_type_id) {
                        props.setSelectedVehicleType(vData?.vehicle_type_id);
                      }
                    }}
                    style={{ minWidth: "auto", width: "auto" }}
                  />{" "}
                  and weighs{" "}
                  <GSelectCheckbox
                    data={weights}
                    title={"Weight"}
                    value={item.weight}
                    option_key="title"
                    option_value="id"
                    handleChange={(v) => {
                      console.log(v);
                      item.weight = v || "";
                      addedItems[ind].weight = v || "";
                      setAddedItems([...addedItems]);
                      props.onChange(addedItems);
                    }}
                    style={{ minWidth: "auto", width: "auto" }}
                  />
                  pounds.
                  <GView style={{ marginTop: "8px" }}>
                    <GText
                      semi
                      text={"Additional Information"}
                      style={{ fontSize: "14px" }}
                    />
                    <GInput
                      type="text"
                      name={"notes_" + ind}
                      id={`notes_filter_${ind}`}
                      value={item.notes}
                      placeholder="Product Number(s), Item Description, and Content Details"
                      maxLength={140}
                      display={item.notes ? "filled" : ""}
                      style={{
                        background: "#fff",
                        borderBottom: "1px solid #e2e2e2",
                        padding: "2px 0",
                        width: "380px",
                      }}
                      divStyle={{ padding: "8px 0 0" }}
                      mainClass={"inline"}
                      onChange={(e) => {
                        item.notes = e.target.value || "";
                        addedItems[ind].notes = e.target.value || "";
                        setAddedItems([...addedItems]);
                        props.onChange(addedItems);
                      }}
                    />
                  </GView>
                </GView>
                <GActions
                  onRemove={() => {
                    addedItems.splice(ind, 1);
                    setAddedItems([...addedItems]);
                    props.onChange(addedItems);
                  }}
                  menuTitle={"Remove"}
                  mode={"dark"}
                  style={{
                    position: "relative",
                    marginLeft: "auto",
                    width: "35px",
                    textAlign: "end",
                  }}
                  menuStyle={{
                    fontSize: "13.5px",
                    padding: "4px 15px",
                  }}
                  paperStyle={{
                    minWidth: "140px",
                    background: "#3a3a3a",
                    color: "#f5f5f5",
                  }}
                />
              </ItemDiv>
            );
          })}
          <GButton
            variant="linkable"
            onClick={() => {
              let validate;
              addedItems.map((item, i) => {
                validate = true;
                if (!item.category) {
                  enqueueSnackbar(`Choose category for item ${i + 1}`, options);

                  validate = false;
                  return;
                }
                if (!item.quantity) {
                  enqueueSnackbar(`Add quantity for item ${i + 1}`, options);
                  validate = false;
                  return;
                }
                if (!item.packaging) {
                  enqueueSnackbar(`Choose package for item ${i + 1}`, options);
                  validate = false;
                  return;
                }
                if (!item.fit) {
                  enqueueSnackbar(`Choose Fit in for item ${i + 1}`, options);
                  validate = false;
                  return;
                }
                if (!item.weight) {
                  enqueueSnackbar(`Choose weight for item ${i + 1}`, options);
                  validate = false;
                  return;
                }
              });
              if (validate == true || addedItems.length == 0) {
                addedItems.push({
                  category: "",
                  packaging: "",
                  fit: "",
                  weight: "",
                  quantity: "",
                  notes: "",
                });
                setAddedItems([...addedItems]);
              }
            }}
          >
            + Add Item
          </GButton>
        </>
      )}
    </GView>
  );
};

export default OrderItems;
