import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import GText from "./GText";
import GView from "./GView";

export const OfferDiv = styled.div`
  padding: 15px;
  margin: 0;
  border: 1px solid #f4f5f9;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: inline-flex;
  & .iconStyle {
    background: ${({ theme }) => theme.body};
    color: #fff;
    height: 24px;
    border-radius: 50%;
    width: 30px;
    text-align: center;
    padding-top: 5px;
    margin: 8px 15px 0 0;
  }
  h3 {
    background: #f8f6dd;
    border: 1px dashed #f1e6cd;
    margin: 0 0 12px;
    padding: 9px 9px 7px;
    color: #63635b;
    font-size: 17px;
    line-height: 28px;
    width: 160px;
    text-align: center;
    cursor: pointer;
  }
  &:hover .MuiSvgIcon-root {
    display: inline-block;
  }
`;

export default function Offers(props) {
  const [couponData, setCouponData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const codeRef = useRef(null);

  useEffect(() => {
    setCouponData(props.data);
  }, []);

  const handleCopy = (c, e) => {
    if (navigator.clipboard != undefined) {
      //Chrome
      navigator.clipboard.writeText(c.code).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    } else if (window.clipboardData) {
      // Internet Explorer
      window.clipboardData.setData("Text", c.code);
    } else {
      console.log("cc");
      //codeRef.current.select();
      document.execCommand("copy");
      e.target.focus();
    }

    if (props.copy == true) {
      enqueueSnackbar("Copied : " + c.code);
    }
    if (props.onCopy) {
      props.onCopy(c);
    }
  };
  return (
    <OfferDiv>
      <GView className="iconStyle">%</GView>
      <GView style={{ width: "calc(100% - 40px)" }}>
        <h3 onClick={(e) => handleCopy(couponData, e)}>
          <input
            readOnly
            value={couponData.code}
            ref={codeRef}
            style={{
              background: "transparent",
              border: "0",
              outline: "0",
              cursor: "pointer",
            }}
          />
        </h3>
        <GText g5={"true"} semi={"true"} text={couponData.title} />
        <GText
          dark
          text={couponData.description}
          style={{ letterSpacing: "0", margin: "2px 0" }}
        />

        {couponData.expiry && (
          <GText
            med={"true"}
            g6={"true"}
            text={"Expires : " + couponData.expiry_formatted}
          />
        )}
      </GView>
    </OfferDiv>
  );
}
