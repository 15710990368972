import React, { useState, useEffect, useContext } from "react";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "../Elements/GText";
import OrderStops from "../Components/orderQuoteStops";
import GTaxiMap from "../Components/orderStopsMap";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { SettingsContext } from "../DataFactory/useSettings";
import { useNavigate } from "react-router-dom";
import GButton from "../Elements/GButton";
import styled from "styled-components";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as UnCheck } from "../assets/images/untick.svg";
import Modal from "react-modal";

const DIV = styled.div`
  padding: 0 10px 15px;
  & .inputDiv {
    display: flex;
    padding: 2px 0;
    margin: 10px 0;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      &.checked {
        path {
          fill: ${({ theme }) => theme.body};
        }
      }
    }
    label {
      font-size: 14px;
      color: #333;
      cursor: pointer;
      width: calc(100% - 30px);
    }
  }
`;

const OrderQuoteDiv = styled.div`
  text-align: center;
  padding: 0 0 7vh 0;

  h4 {
    color: ${({ theme }) => theme.body};
    margin: 5px 0;
  }
  hr {
    margin: 12px 0;
    border: 0;
    border-bottom: 1px solid #e2e2e2;
  }
`;

const deliveryTypes = [
  { title: "I need it be delivered asap", value: "asap" },
  {
    title:
      "I'm flexible (Rates are lesser than the immediately delivered orders)",
    value: "flex",
  },
];

const OrderQuote = () => {
  const { width } = useWindowSize();
  let navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { settings } = useContext(SettingsContext);
  const [places, setPlaces] = useState([]);
  const [orderData, setOrderData] = useState({
    stops: [],
    delivery_type: "",
  });
  const [showOrderQuoteSuccess, setShowOrderQuoteSuccess] = useState(false);
  const [quoteResponse, setQuoteResponse] = useState("");

  useEffect(() => {
    orderData.stops = places;
    setOrderData({ ...orderData });
  }, [places]);

  function calculateQuote() {
    if (orderData.stops.length == 0) {
      enqueueSnackbar("Choose Pickup address");
      return false;
    }
    if (orderData.stops.length > 0 && orderData.stops.length < 2) {
      enqueueSnackbar("Choose Delivery address");
      return false;
    }
    if (!orderData.delivery_type) {
      enqueueSnackbar("Choose Delivery type");
      return false;
    }
    setIsDisabled(true);
    let COrderData = { ...orderData };
    ApiService({
      method: "POST",
      route: "c/quick-quote",
      body: COrderData,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setShowOrderQuoteSuccess(true);
          setQuoteResponse(response.data);
        } else {
          enqueueSnackbar(response.data.message);
        }

        setIsDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <GView
      style={{
        display: "flex",
        flexDirection: width <= 767 ? "column" : "row",
      }}
    >
      {width <= 767 && (
        <GView
          style={{
            width: "100%",
          }}
        >
          <GTaxiMap
            center={{ lat: 33.7529636, lng: -84.4036709 }}
            zoom={15}
            places={places}
            isAutoComplete={false}
            style={{ position: "relative" }}
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              process.env.REACT_APP_GOOGLE_KEY +
              "&libraries=geometry,drawing,places"
            }
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: "calc(100vh - 80px)",
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </GView>
      )}
      <GView
        style={{
          width: width <= 767 ? "100%" : "30%",
          minWidth: "350px",
          background: "#f5f6f9",
        }}
      >
        <GView
          style={{
            height: width > 767 ? "calc(100vh - 175px)" : "auto",
            overflow: "overlay",
            padding: "10px 20px 10px",
          }}
        >
          <>
            <GView style={{ margin: "20px 0" }}>
              <GText
                g4
                semi
                text={"Quick Qquote Delivery Estimate"}
                style={{ margin: "10px 0 0" }}
              />
              <GText
                g6
                med
                text={
                  "(Actual pricing is based on leadtime, time of day and size of items being delivered.)"
                }
                style={{ margin: "0 0 15px" }}
              />
              <OrderStops setPlaces={setPlaces} address={[]} stops={[]} />
            </GView>

            <GText
              semi
              med
              text={"Delivery Type"}
              style={{
                paddingTop: "10px",
                margin: "10px 12px 15px",
                fontSize: "14.5px",
                color: "#333",
              }}
            />
            <DIV>
              {deliveryTypes.map((o, ind) => {
                return (
                  <GView
                    className="inputDiv"
                    onClick={() => {
                      orderData.delivery_type = o.value;
                      setOrderData({ ...orderData });
                    }}
                    key={ind}
                  >
                    <input
                      name={"delivery_type"}
                      type="radio"
                      id={"vs_option_" + ind}
                      value={o}
                      defaultChecked={orderData.delivery_type == o.value}
                      style={{ display: "none" }}
                    />
                    {orderData.delivery_type === o.value ? (
                      <Check className="checked" />
                    ) : (
                      <UnCheck />
                    )}
                    &nbsp;&nbsp;
                    <label htmlFor={"vs_option_" + ind} style={{}}>
                      {o.title}
                    </label>
                  </GView>
                );
              })}
            </DIV>
          </>
        </GView>
        <GButton
          variant="condensed"
          children="Calculate"
          loading={isDisabled}
          style={{
            width: "calc(100% - 50px)",
            margin: "20px 25px",
            textTransfoem: "uppercase",
            fontSize: "14px",
            padding: "0.8em",
            fontWeight: "550",
            borderRadius: "2px",
          }}
          onClick={() => {
            calculateQuote();
          }}
        />
      </GView>

      {width > 767 && (
        <GView
          style={{
            width: "70%",
            position: "relative",
          }}
        >
          <GTaxiMap
            center={{ lat: 33.7529636, lng: -84.4036709 }}
            zoom={15}
            places={places}
            isAutoComplete={false}
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              process.env.REACT_APP_GOOGLE_KEY +
              "&libraries=geometry,drawing,places"
            }
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: "calc(100vh - 80px)",
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </GView>
      )}

      <Modal
        isOpen={showOrderQuoteSuccess}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowOrderQuoteSuccess(true)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          },
        }}
        contentLabel="Order Quote Sucess Modal"
      >
        <div style={{ padding: "10px 20px", textAlign: "center" }}>
          <h3>Order Quote</h3>

          <OrderQuoteDiv>
            <GText theme semi text={quoteResponse?.message} />
            <hr />
            <GText med text={"Your estimated quote is"} />
            <h3>{settings?.currency_symbol + quoteResponse?.data}</h3>
          </OrderQuoteDiv>
          <GView style={{ textAlign: "right" }}>
            <GButton
              variant="linkable"
              children="Cancel"
              style={{ marginRight: "20px" }}
              onClick={() => setShowOrderQuoteSuccess(false)}
            />
            <GButton
              variant="condensed"
              children="Okay"
              onClick={() => {
                navigate({
                  pathname: "/",
                });
                // window.location.reload();
              }}
            />
          </GView>
        </div>
      </Modal>
    </GView>
  );
};

export default OrderQuote;
