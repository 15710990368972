import React, { useState, useEffect, useContext } from "react";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "../Elements/GText";
import VehicleSection from "../Components/orderVehicles";
import OrderStops from "../Components/orderStops";
// import MapWithMarkers from "../Components/orderStopsMap";
import GTaxiMap from "../Components/orderStopsMap";
import FormSection from "../Components/orderForm";
import InstructionSection from "../Components/orderInstructions";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { SettingsContext } from "../DataFactory/useSettings";
import GButton from "../Elements/GButton";
import CheckoutForm from "./orderCheckout";
import Modal from "react-modal";
import {
  GCardShimmer,
  GRoundShimmer,
  GLineShimmer,
} from "../Elements/gShimmer";
import OrderSteps from "../Elements/gStepRoutes";
import OrderTimeslots from "../Components/orderTimeSlots";
import OrderReview from "../Components/orderReview";
import OrderItems from "../Components/orderItems";
import { ReactComponent as ArrowNext } from "../assets/images/arrowNext.svg";
import { ReactComponent as Close } from "../assets/images/cancel.svg";

const steps = [
  {
    index: 1,
    title: "Location",
  },
  {
    index: 2,
    title: "Items",
  },
  {
    index: 3,
    title: "Time",
  },
  {
    index: 4,
    title: "Instructions",
  },
  {
    index: 5,
    title: "Review",
  },
];

const OrderCreate = () => {
  const { width } = useWindowSize();
  const [isDisabled, setIsDisabled] = useState(false);
  const [activeIndex, setSelectedActiveInd] = useState(steps[0].index);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const {
    addresses,
    fields,
    vehicle_types,
    special_instructions,
    items = [],
  } = formData;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { settings } = useContext(SettingsContext);
  const [selectedVehicleType, setSelectedVehicleType] = useState("1");
  const [selectedItems, setSelectedItems] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [places, setPlaces] = useState([]);
  const [isFormValidate, setIsFormValidate] = useState(false);
  const [orderData, setOrderData] = useState({
    stops: [],
    fields: [],
    points: false,
    tip: 0,
    vehicle_type_id: selectedVehicleType,
    scheduled_from: "",
    scheduled_to: "",
    special_instructions: "",
  });
  const [checkoutVisible, setCheckoutVisible] = useState(false);

  const action = (snackbarId) => (
    <>
      <GButton
        variant="linkable"
        style={{ color: "#fff" }}
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <Close style={{ fill: "#fff", width: "20px", height: "20px" }} />
      </GButton>
    </>
  );

  let options = {
    persist: true,
    variant: "error",
    maxSnack: 2,
    action,
  };

  useEffect(() => {
    ApiService({ method: "OPTIONS", route: "c/orders" })
      .then((response) => {
        console.log("gh" + response);
        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setFormData(response.data.data);
            orderData.fields = response.data?.data?.fields || [];
            setOrderData({ ...orderData });
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(()=>{
    window.scrollTo(0, 0);
    var myDiv = document.getElementById('innerStepsView');
myDiv.scrollTop = 0;
  },[activeIndex])

  useEffect(() => {
    console.log(selectedVehicleType);
    orderData.vehicle_type_id = selectedVehicleType;
    orderData.fields = formFields.length > 0 ? formFields : fields;
    orderData.items = selectedItems.length > 0 ? selectedItems : [];
    setOrderData({ ...orderData });
  }, [selectedVehicleType, formFields, selectedItems]);

  useEffect(() => {
    orderData.stops = places;
    setOrderData({ ...orderData });
  }, [places]);

  function orderCheckout() {
    setIsDisabled(true);
    let COrderData = { ...orderData };
    ApiService({
      method: "POST",
      route: "c/order/checkout",
      body: COrderData,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setSelectedActiveInd(activeIndex + 1);
        } else {
          enqueueSnackbar(response.data.message, options);
        }

        setIsDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function showValidations() {
    if (activeIndex >= 1) {
      if (!orderData.vehicle_type_id) {
        enqueueSnackbar("Choose Vehicle is required", options);
        return false;
      }
      if (orderData.stops.length == 0) {
        enqueueSnackbar("Choose Pickup address", options);
        return false;
      }
      if (orderData.stops.length > 0 && orderData.stops.length < 2) {
        enqueueSnackbar("Choose Delivery address", options);
        return false;
      }
    }

    if (activeIndex >= 2) {
      if (orderData.items.length > 0) {
        let iValidate;
        orderData.items.map((item, i) => {
          iValidate = true;
          if (!item.category) {
            enqueueSnackbar(`Choose category for item ${i + 1}`, options);
            iValidate = false;
            return;
          }
          if (!item.quantity) {
            enqueueSnackbar(`Add quantity for item ${i + 1}`, options);
            iValidate = false;
            return;
          }
          if (!item.packaging) {
            enqueueSnackbar(`Choose package for item ${i + 1}`, options);
            iValidate = false;
            return;
          }
          if (!item.fit) {
            enqueueSnackbar(`Choose Fit in for item ${i + 1}`, options);
            iValidate = false;
            return;
          }
          if (!item.weight) {
            enqueueSnackbar(`Choose weight for item ${i + 1}`, options);
            iValidate = false;
            return;
          }
        });

        if (iValidate != true) {
          return false;
        }
      }
    }
    if (activeIndex >= 3) {
      if (!orderData.scheduled_from || !orderData.scheduled_to) {
        enqueueSnackbar("Scheduled time is required", options);
        return false;
      }
    }
    if (activeIndex >= 4) {
      if (orderData.fields.length >= 0) {
        let validate = true;
        let m = "";
        orderData.fields.map((f) => {
          console.log(f);
          if (f.required === 1) {
            if (!f.value) {
              validate = false;
              m = f;
            }
          }
        });
        if (validate == false) {
          enqueueSnackbar(m.title + " is required", options);
          return false;
        }
      }
    }
    return true;
  }

  return (
    <GView
      style={{
        display: "flex",
        flexDirection: width <= 767 ? "column" : "row",
      }}
    >
      {width <= 767 && (
        <GView
          style={{
            width: "100%",
          }}
        >
          <GTaxiMap
            center={{ 
              lat: settings?.default_address?.lat ? Number(settings?.default_address?.lat) :   33.7529636, 
              lng: settings?.default_address?.lng ? Number(settings?.default_address?.lng) : -84.4036709 
            }}
            zoom={15}
            places={places}
            isAutoComplete={false}
            style={{ position: "relative" }}
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              process.env.REACT_APP_GOOGLE_KEY +
              "&libraries=geometry,drawing,places"
            }
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: "calc(100vh - 80px)",
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </GView>
      )}
      <GView
        style={{
          width: width <= 767 ? "100%" : "35%",
          minWidth: width <= 767 ? "374px" : "450px",
          background: "#f5f6f9",
        }}
      >
        <OrderSteps
          activeIndex={activeIndex}
          routes={steps}
          // onSelectTab={(r) => setSelectedActiveInd(r.index)}
        />
        <GView  
        id={"innerStepsView"}
          style={{
            height: width > 767 ? "calc(100vh - 225px)" : "auto",
            overflow: "overlay",
            padding: "0px 20px",
            margin: "10px 0",
          }}
        >
          {activeIndex == 1 && (
            <>
              {isLoading == true ? (
                <>
                  <GLineShimmer />
                  <GRoundShimmer></GRoundShimmer>
                  <GLineShimmer />
                  <GCardShimmer
                    type={"single"}
                    color={"#DBDCDD"}
                  ></GCardShimmer>
                </>
              ) : (
                <>
                  {vehicle_types && vehicle_types.length > 0 && (
                    <VehicleSection
                      data={vehicle_types}
                      selectedVehicleType={selectedVehicleType}
                      onSelect={(id) => setSelectedVehicleType(id)}
                    />
                  )}

                  <GView style={{ margin: "20px 0" }}>
                    <GText
                      g4
                      semi
                      text={"Select Stops"}
                      style={{ margin: "10px 0" }}
                    />
                    {addresses && (
                      <OrderStops
                        setPlaces={setPlaces}
                        address={addresses}
                        stops={orderData.stops}
                        // onChange={(data) => {
                        //   console.log("data", data);
                        //   setPlaces(data);
                        // }}
                      />
                    )}
                  </GView>
                </>
              )}
            </>
          )}
          {activeIndex == 2 && (
            <>
              <OrderItems
                orderData={orderData}
                setSelectedVehicleType={setSelectedVehicleType}
                data={items}
                onChange={(iData) => {
                  setSelectedItems(iData);
                }}
              />
            </>
          )}
          {activeIndex == 3 && (
            <>
              <OrderTimeslots
                orderData={orderData}
                settings={settings}
                setOrderData={setOrderData}
              />
            </>
          )}
          {activeIndex == 4 && (
            <>
              <GView>
                <GText
                  g4
                  semi
                  text={"Delivery Instructions & Information"}
                  style={{ margin: "10px 0 20px" }}
                />
                {special_instructions && special_instructions.length > 0 && (
                  <InstructionSection
                    data={special_instructions}
                    orderData={orderData}
                    settings={settings}
                    setOrderData={setOrderData}
                  />
                )}
                {fields && fields.length > 0 && (
                  <FormSection
                    data={fields}
                    settings={settings}
                    onChange={(fields) => {
                      setFormFields(fields);
                    }}
                  />
                )}
              </GView>
            </>
          )}

          {activeIndex == 5 && (
            <>
              <OrderReview
                data={orderData}
                settings={settings}
                vehicleTypes={vehicle_types}
                special_instructions={special_instructions}
                items={items}
              />
            </>
          )}
        </GView>

        <GView>
          {activeIndex > 1 && (
            <GButton
              variant="outlined"
              style={{
                width: "auto",
                margin: "10px 20px 15px",
                fontSize: "15px",
                padding: "0.8em 1.5em",
                fontWeight: "550",
                borderRadius: "2px",
                letterSpacing: "0.5px",
                display: "inline-flex",
              }}
              onClick={() => {
                if (activeIndex > 1) {
                  setSelectedActiveInd(activeIndex - 1);
                }
              }}
            >
              <ArrowNext
                style={{
                  transform: "rotate(180deg)",
                  // fill: "#fff",
                  width: "17px",
                  height: "17px",
                  marginRight: "5px",
                }}
              />{" "}
              Back{" "}
            </GButton>
          )}
          {activeIndex == 5 ? (
            <>
              {" "}
              {isLoading != true && (
                <GButton
                  variant="condensed"
                  // disabled={!isFormValidate}
                  children="Proceed to Payment"
                  style={{
                    // width: "calc(100% - 50px)",
                    margin: "10px 20px 15px",
                    textTransfoem: "uppercase",
                    fontSize: "14px",
                    padding: "0.9em 1.5em",
                    fontWeight: "550",
                    borderRadius: "2px",
                    float: "right",
                    letterSpacing: "0.5px",
                    display: "inline-flex",
                  }}
                  onClick={() => {
                    let v = showValidations();
                    if (v == true) {
                      setCheckoutVisible(true);
                    }
                  }}
                />
              )}
            </>
          ) : (
            <GButton
              variant="condensed"
              disabled={isDisabled}
              style={{
                width: "auto",
                margin: "10px 20px 15px",
                fontSize: "15px",
                padding: "0.8em 1.5em",
                fontWeight: "550",
                borderRadius: "2px",
                float: "right",
                letterSpacing: "0.5px",
                display: "inline-flex",
              }}
              onClick={() => {
                let v = showValidations();
                if (v == true) {
                  if (activeIndex == 1) {
                    orderCheckout();
                  } else {
                    setSelectedActiveInd(activeIndex + 1);
                  }
                }
              }}
            >
              Continue{" "}
              <ArrowNext
                style={{
                  fill: "#fff",
                  width: "17px",
                  height: "17px",
                  marginLeft: "5px",
                }}
              />
            </GButton>
          )}
        </GView>
      </GView>

      {width > 767 && (
        <GView
          style={{
            width: "65%",
            position: "relative",
          }}
        >
          <GTaxiMap
            center={{ 
              lat: settings?.default_address?.lat ? Number(settings?.default_address?.lat) :   33.7529636, 
              lng: settings?.default_address?.lng ? Number(settings?.default_address?.lng) : -84.4036709 
            }}
            zoom={15}
            places={places}
            isAutoComplete={false}
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              process.env.REACT_APP_GOOGLE_KEY +
              "&libraries=geometry,drawing,places"
            }
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: "calc(100vh - 80px)",
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </GView>
      )}

      <Modal
        isOpen={checkoutVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCheckoutVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
          },
        }}
        contentLabel="Checkout Modal"
      >
        <CheckoutForm
          checkoutVisible={checkoutVisible}
          setCheckoutVisible={setCheckoutVisible}
          data={{ ...orderData }}
        />
      </Modal>
    </GView>
  );
};

export default OrderCreate;
