import React, { useState } from "react";
import { ApiService } from "../services";
import GButton from "../Elements/GButton";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

export default function PaymentForm(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const initialValues = {
    cvc: "",
    exp_month: "",
    exp_year: "",
    name: "",
    card_number: "",
    identifier: props.cardType || "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);
    // return;
    ApiService({
      method: "POST",
      route: "c/cards",
      body: values,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          props.onCardAdd(response.data.data);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = Yup.object({
    card_number: Yup.string().required("Required"),
    exp_month: Yup.string().required("Required"),
    exp_year: Yup.string().required("Required"),
    cvc: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
  });

  return (
    <div id="PaymentForm">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => (
          <Form>
            <FormikControl
              control="input"
              type="text"
              label="Card Number"
              name="card_number"
              maxLength={16}
              divstyle={{ padding: "4px 5px" }}
              style={{ fontSize: "13px", height: "35px" }}
              onChange={(event) => {
                const re = /^[0-9\b]+$/;
                if (event.target.value && !re.test(event.target.value)) {
                  return;
                } else {
                  setFieldValue("card_number", event.target.value);
                }
              }}
            />

            <div style={{ display: "inline-flex", width: "100%" }}>
              <FormikControl
                control="input"
                type="text"
                label="MM"
                name="exp_month"
                maxLength={2}
                divstyle={{ padding: "4px 5px" }}
                style={{ fontSize: "13px", height: "35px" }}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("exp_month", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label="YYYY"
                name="exp_year"
                maxLength={4}
                divstyle={{ padding: "4px 5px" }}
                style={{ fontSize: "13px", height: "35px" }}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("exp_year", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label="CVC"
                name="cvc"
                maxLength={4}
                divstyle={{ padding: "4px 5px" }}
                style={{ fontSize: "13px", height: "35px" }}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("cvc", event.target.value);
                  }
                }}
              />
            </div>

            <FormikControl
              control="input"
              type="text"
              divstyle={{ padding: "4px 5px" }}
              style={{ fontSize: "13px", height: "35px" }}
              label="Name on Card"
              name="name"
            />

            <GButton
              variant="condensed"
              loading={isBtnLoading}
              children={"Submit"}
              type="submit"
              style={{
                float: "right",
                margin: "10px 5px",
                padding: "8px 25px",
                fontSize: "13px",
              }}
            />
            <GButton
              variant="outlined"
              children="Cancel"
              type="button"
              style={{
                float: "right",
                margin: "10px 5px",
                padding: "7px 25px",
                fontSize: "13px",
              }}
              onClick={() => props.setAddCard(false)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
