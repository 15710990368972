import React, { useState } from "react";
import GText from "./GText";
import GView from "./GView";
import QuoteImg from "../assets/images/quote.png";
import OrderDeliveryInfo from "../Components/orderDeliveryInfo";
import Modal from "react-modal";
import GButton from "./GButton";

const GRouteAddress = (props) => {
  const [odInfovisible, setOdInfovisible] = useState(false);
  const [selectedTaskInfo, setSelectedTaskInfo] = useState({});
  return (
    <>
      {props.task.map((t, i) => {
        return (
          <GView style={{ padding: "5px 0" }} key={i}>
            {props.showInfo == true && (
              <GButton
                variant="linkable"
                onClick={() => {
                  setSelectedTaskInfo(t);
                  setOdInfovisible(true);
                }}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontSize: "14px",
                  float: "right",
                  fontStyle: "italic",
                }}
              >
                More Info
              </GButton>
            )}
            {props.status === true && (
              <GText
                semi
                text={t.status_display?.title}
                style={{
                  color: "#" + t.status_display?.color1,
                  marginbottom: "3px",
                }}
              />
            )}

            <GText
              g6
              dark
              text={
                t.task_time
                  ? (t.type == "pickup" ? "Pick-Up" : t.type) +
                    " - " +
                    t.task_time
                  : t.type == "pickup"
                  ? "Pick-Up"
                  : t.type
              }
              style={{ textTransform: "capitalize", fontStyle: "italic" }}
            />

            <GText
              med
              text={t.rec_name + " - " + t.rec_phone}
              style={{
                textTransform: "capitalize",
                fontStyle: "italic",
                margin: "1px 0",
                fontSize: "14px",
              }}
            />
            <GText
              semi
              text={t?.location_name}
              style={{color: "#333", fontSize: "14px"}}
            />
            <GText
              med
              text={t.address}
              style={{ color: "#333", fontSize: "14px" }}
            />
            {props.status === true && t.notes ? (
              <GView style={{ display: "flex", marginTop: "5px" }}>
                <img
                  src={QuoteImg}
                  alt="quotes"
                  width={"16px"}
                  height={"16px"}
                  style={{ marginRight: "10px" }}
                />

                <GText
                  text={t.notes}
                  style={{
                    fontStyle: "italic",
                    fontSize: "14px",
                    color: "#333",
                  }}
                />
              </GView>
            ) : (
              ""
            )}
          </GView>
        );
      })}

      <Modal
        isOpen={odInfovisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOdInfovisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Order Delivery Info Form Modal"
      >
        <OrderDeliveryInfo
          odInfovisible={odInfovisible}
          setOdInfovisible={setOdInfovisible}
          linkedId={props.orderId}
          taskId={selectedTaskInfo.id}
          notes={selectedTaskInfo?.task_notes}
          images={selectedTaskInfo?.task_images}
          signatures={selectedTaskInfo?.task_signatures}
        />
      </Modal>
    </>
  );
};

export default GRouteAddress;
