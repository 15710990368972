import React from "react";
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";

function datePicker(props) {
  const { label, name, format, ...rest } = props;

  return (
    <div className="form-control" style={props.divstyle}>
      {label ? <label htmlFor={name}>{label}</label> : ""}
      <Field name={name} {...rest}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;

          return (
            <DateView
              id={name}
              {...field}
              {...rest}
              selected={value}
              onChange={(val) => {
                setFieldValue(name, val);
              }}
              dateFormat={format}
              showYearDropdown
              showTimeSelect={props.showTimeSelect || false}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default datePicker;
