import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";
import ForgotPassword from "../forms/forgotPassword";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import GView from "../Elements/GView";
import Modal from "react-modal";
import HomeContent from "../Components/homeContent";
import { ImgUrl } from "../services";
import GText from "../Elements/GText";

const Home = () => {
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [signinvisible, setSigninvisible] = useState(false);
  const { settings } = useContext(SettingsContext);

  const customStyles = {
    content: {
      width: "400px",
    },
  };

  return (
    <>
      <GView
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "auto",
          minHeight: "400px",
          backgroundImage: `url(${ImgUrl("website") + "/" + settings?.web?.web_home_header
            })`,
          backgroundSize: "cover",
          backgroundPosition: "100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <GText
          g2="true"
          bold
          text={settings?.web?.web_home_title}
          style={{
            color: settings?.web?.slider_text_color || "#000",
            marginTop: "auto",
            textShadow: settings?.web?.web_home_header
              ? "0.5px 0.5px #f9f9f9"
              : "none",
          }}
        />
        {settings?.web?.web_home_desc && (
          <GText
            text={settings?.web?.web_home_desc}
            style={{
              padding: "10px 0",
              color: settings?.web?.slider_text_color || "#000",
              textShadow: settings?.web?.web_home_header
                ? "0.5px 0.5px #f9f9f9"
                : "none",
            }}
          />
        )}
        {settings?.user?.id ? (
          <Link
            to={settings?.theme == "3" ? "/create-order" : "/add-order"}
            style={{
              margin: "20px 0 auto",
            }}
          >
            <GButton
              variant="condensed"
              children="Create New Order"
              style={{
                borderRadius: "20px",
                fontWeight: "600",
                padding: "12px 30px",
                boxShadow:
                  "0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)",
              }}
            />
          </Link>
        ) : (
          <GButton
            variant="condensed"
            children="Create New Order"
            style={{
              borderRadius: "20px",
              margin: "20px 0 auto",
              fontWeight: "600",
              padding: "12px 30px",
              boxShadow:
                "0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)",
            }}
            onClick={() => {
              setSigninvisible(true);
            }}
          />
        )}
        <Link
          to={"/order-quote"}
          style={{
            margin: "20px 0 auto",
          }}
        >
          <GButton
            variant="outlined"
            children="Get A Quote"
            style={{
              borderRadius: "20px",
              fontWeight: "600",
              padding: "12px 30px",
              boxShadow:
                "0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)",
            }}
          />
        </Link>
      </GView>

      <HomeContent data={settings?.plugins?.homepage} />

      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
    </>
  );
};

export default Home;
