import React, { useContext, useState } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import AddressView from "../Elements/gAddressView";
import AddressForm from "../forms/addressForm";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import { Formik, Form } from "formik";
import FormikControl from "../formikComponents/formikControl";
import { useEffect } from "react";

const InviteDiv = styled.div`
  margin: 10px;
  padding: 0 10px;
  hr {
    border: 0;
    border-bottom: 1px solid #f2f2f2;
    margin: 15px 0;
  }
  h3 {
    border: 1px dashed #a2a2a2;
    margin: 5px 0;
    padding: 8px 25px 7px;
    color: #333;
    font-size: 17px;
    line-height: 28px;
    display: inline-block;
    min-width: 150px;
    text-align: center;
    cursor: pointer;
  }
`;

export default function Settings() {
  const [advisible, setAdvisible] = useState(false);
  const { settings, setSettings, refreshSettings } =
    useContext(SettingsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const { width } = useWindowSize();
  console.log(settings);
  const initialValues = {
    pickup_notes: settings?.user?.pickup_notes || "",
    delivery_notes: settings?.user?.delivery_notes || "",
  };

  useEffect(() => {
    refreshSettings();
  }, []);
  const onSubmit = (values) => {
    setBtnLoading(true);
    let sendData = { ...values };
    console.log(sendData);

    ApiService({ method: "PUT", route: "c/me", body: sendData }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          settings.user = response.data.data;
          setSettings({ ...settings });
        }
        setBtnLoading(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };
  return (
    <div>
      <GText g2="true" theme bold text={"Default Instructions"} />
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px 0 ",
          margin: "10px 0",
        }}
      >
        <GView
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: width <= 991 ? "column" : "row",
          }}
        >
          <GView
            style={{
              padding: "10px 15px 20px",
              minWidth: width <= 991 ? "100%" : "40%",
              boxSizing: "border-box",
              borderRight: width <= 991 ? "0" : "1px solid #f2f2f2",
              borderBottom: width <= 991 ? "1px solid #f2f2f2" : "0",
            }}
          >
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ values }) => (
                <Form>
                  <FormikControl
                    control="input"
                    as="textarea"
                    type="text"
                    label="Default Pickup Instructions"
                    name="pickup_notes"
                    rows={2}
                    maxLength={140}
                  />
                  <FormikControl
                    control="input"
                    as="textarea"
                    type="text"
                    label="Default Delivery Instructions"
                    name="delivery_notes"
                    rows={2}
                    maxLength={140}
                  />
                  <GButton
                    variant="condensed"
                    children="Update"
                    style={{ margin: "10px 15px", width: "auto" }}
                    type="submit"
                    loading={isBtnLoading}
                  />
                </Form>
              )}
            </Formik>
          </GView>
          <GView
            style={{
              flex: "1 1 1%",
              width: width <= 991 ? "100%" : "auto",
              padding: "10px 20px",
              boxSizing: "border-box",
            }}
          >
            <GText
              g4
              semi
              text={"Default Pickup Address"}
              style={{ margin: "10px 0" }}
            />
            {settings?.default_address?.id ? (
              <GView style={{ margin: "20px 0", maxWidth: "450px" }}>
                <AddressView
                  dropdownActions={true}
                  onEdit={(sAddress) => {
                    setAdvisible(true);
                  }}
                  addressData={settings?.default_address}
                  onSubmit={refreshSettings}
                  default={false}
                />
              </GView>
            ) : (
              <GButton variant="linkable" onClick={() => setAdvisible(true)}>
                + Add Address
              </GButton>
            )}
          </GView>
        </GView>
      </GView>

      <Modal
        isOpen={advisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAdvisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "500px",
            paddingBottom: "0",
          },
        }}
        contentLabel="Address Modal"
      >
        <AddressForm
          advisible={advisible}
          setAdvisible={setAdvisible}
          data={settings?.default_address || {}}
          onSubmit={() => {
            refreshSettings();
          }}
          isEmpty={settings?.default_address ? false : true}
          default={1}
        />
      </Modal>
    </div>
  );
}
