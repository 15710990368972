import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Cancel } from "../assets/images/cancel.svg";
import styled from "styled-components";
import TipImg from "../assets/images/tip-icon.png";
import GText from "../Elements/GText";

const tipValues = [5, 10, 15];
const TipDiv = styled.div`
  padding: 15px 25px 20px;
  img {
    width: 35px;
  }
  ul {
    margin-top: 5px;
    list-style-type: none;
    display: inline-flex;
    flex-wrap: wrap;
    li {
      color: #333;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      width: 43px;
      text-align: center;
      padding: 6px 7px;
      font-size: 14px;
      margin: 5px 10px 0 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      // align-items: center;

      &.active {
        border: 1px solid ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.body};
        p {
          color: ${({ theme }) => theme.body}!important;
          font-weight: 500;
        }
        svg {
          fill: ${({ theme }) => theme.body};
        }
      }
      & svg {
        padding: 0px 0 0 4px;
        vertical-align: top;
      }
    }
  }
`;

export default function CheckoutOrderTip(props) {
  const [appliedTip, setAppliedTip] = useState("");
  useEffect(() => {
    setAppliedTip(props.selectedTip);
  }, [props]);
  return (
    <TipDiv>
      <img src={TipImg} alt="Tip" width="35px" height="auto" />
      <React.Fragment>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            width: "calc(100% - 65px)",
            padding: "0 15px",
          }}
        >
          <GText semi text={"Tip your delivery partner"} />
          <GText
            g5
            med
            style={{ padding: "5px 0" }}
            text={
              "100% of the tips go to your delivery partner. Tips are always optional."
            }
          />
          <ul>
            {tipValues.map((key, ival) => {
              return (
                <li
                  key={ival}
                  onClick={() => {
                    if (appliedTip === key) {
                      props.setSelectedTip("");
                    } else {
                      props.setSelectedTip(key);
                    }
                  }}
                  className={appliedTip === key ? "active" : ""}
                >
                  <GText
                    text={props.currencySymbol + "" + key}
                    style={{ lineHeight: "15px" }}
                  />
                  {appliedTip === key ? (
                    <Cancel style={{ width: "12px", height: "14px" }} />
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    </TipDiv>
  );
}
