import React, { useEffect, useState, useRef } from "react";
import { ImgUrl, addDefaultSrc } from "../services";
import { useSnackbar } from "notistack";
import { NoteDiv } from "../styledComponents/notesStyles";
// import GLightBox from "../Elements/gLightBox";
import GText from "../Elements/GText";
import GButton from "../Elements/GButton";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";

const idStyle = {
  float: "right",
  fontSize: "18px",
  color: "#0381FC",
  margin: "5px",
  fontWEight: "400",
  fontStyle: "italic",
};
const imgStyle = {
  // cursor: "pointer",
  width: "80px",
  height: "80px",
  objectFit: "cover",
  border: "1px solid #e2e2e2",
  padding: "5px",
};

export default function OrderDeliveryInfo(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [notes, setNotes] = useState([]);
  const [images, setImages] = useState([]);
  const [signatures, setSigns] = useState([]);
  const [selectedImgPreview, setSelectedImgPreview] = useState(false);
  const [isImgPreview, setImgPreview] = useState(false);
  const [selectedImgType, setSelectedImgType] = useState("");

  useEffect(() => {
    setNotes(props.notes || []);
    setImages(props.images || []);
    setSigns(props.signatures || []);
  }, [props]);

  if (props.odInfovisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOdInfovisible(false)}
        />
        Delivery Info
        <GText
          g4
          text={`#${props.linkedId}`}
          bold
          theme
          style={{
            margin: "0",
            marginLeft: "auto",
            fontStyle: "italic",
            width: "auto",
            fontSize: "14px",
          }}
        />
      </div>
      <div className="modal-body">
        <div
          style={{
            padding: "0 15px 15px",
            marginBottom: "20px",
          }}
        >
          <h4 style={{ margin: "5px 0 15px" }}>Notes ({notes.length}) </h4>

          {notes.map((p) => {
            return (
              <div key={p.id}>
                <NoteDiv className={"send"}>
                  <h5>{p.notes}</h5>
                  <p>{p.created}</p>
                </NoteDiv>
              </div>
            );
          })}
          {notes.length === 0 ? (
            <p style={{ color: "#b2b2b2", textAlign: "center" }}>
              No notes found
            </p>
          ) : (
            ""
          )}
        </div>
        <hr />
        <div
          style={{
            padding: "5px 15px 10px",
            marginBottom: "20px",
          }}
        >
          <h4 style={{ margin: "10px 0 15px" }}>Images ({images.length})</h4>

          {images.map((p, i) => {
            return (
              <div
                key={p.id}
                style={{ display: "inline-block", margin: "5px" }}
              >
                <img
                  alt=""
                  src={ImgUrl("dimage") + "/" + p.image_path}
                  onError={addDefaultSrc}
                  className="thumbnail"
                  style={imgStyle}
                  onClick={() => {
                    setSelectedImgPreview(p.image_path);
                    setImgPreview(true);
                    setSelectedImgType("dimage");
                  }}
                />
              </div>
            );
          })}

          {images.length === 0 ? (
            <p style={{ color: "#b2b2b2", textAlign: "center" }}>
              No images found
            </p>
          ) : (
            ""
          )}
        </div>
        <hr />
        <div
          style={{
            padding: "5px 15px 10px",
            marginBottom: "20px",
          }}
        >
          <h4 style={{ margin: "10px 0 15px" }}>
            Signatures ({signatures.length}){" "}
          </h4>

          {signatures.map((p) => {
            return (
              <div
                key={p.id}
                style={{ display: "inline-block", margin: "5px 10px" }}
              >
                <img
                  alt=""
                  src={ImgUrl("dsignature") + "/" + p.signature_path}
                  onError={addDefaultSrc}
                  className="thumbnail"
                  style={imgStyle}
                  onClick={() => {
                    setSelectedImgPreview(p.signature_path);
                    setImgPreview(true);
                    setSelectedImgType("dsignature");
                  }}
                />
              </div>
            );
          })}

          {signatures.length === 0 ? (
            <p style={{ color: "#b2b2b2", textAlign: "center" }}>
              No signatures found
            </p>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* {isImgPreview === true ? (
        <GLightBox
          images={selectedImgPreview}
          isImgPreview={isImgPreview}
          setImgPreview={setImgPreview}
          imgType={selectedImgType}
        ></GLightBox>
      ) : (
        ""
      )} */}
    </>
  );
}
