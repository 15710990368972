import React, { useState, useEffect } from "react";
import GView from "../Elements/GView";
import GText from "../Elements/GText";
import styled from "styled-components";
import useWindowSize from "../DataFactory/useWindowSize";
import {
  GCardShimmer,
  GRoundShimmer,
  GLineShimmer,
} from "../Elements/gShimmer";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";

const Div = styled.div`
  .active {
    background: ${({ theme }) => theme.body};
    color: #fff;
    p {
      color: #fff;
    }
  }
`;

const OrderTimeslots = (props) => {
  console.log(props.orderData);
  const { width } = useWindowSize();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [timeslotsList, setTimeslots] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    setIsLoading(true);
    ApiService({
      method: "POST",
      route: "c/order-timeslots",
      body: {
        vehicle_type_id: props.orderData?.vehicle_type_id || "",
        stops: props.orderData?.stops || [],
        items: props.orderData?.items || [],
      },
    })
      .then((response) => {
        console.log("gh" + response);
        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setTimeslots(response.data.data);
            setSelectedDate(
              props.orderData.scheduled_formatted?.date ||
                response.data.data[0]?.date_display
            );
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.orderData.vehicle_type_id]);

  return (
    <GView style={{ padding: "15px 0" }}>
      <GText
        g4
        semi
        text={"Choose Timeslot"}
        style={{ marginBottom: "20px" }}
      />
      {isLoading == true ? (
        <>
          <GLineShimmer />
          <GLineShimmer />
          <GCardShimmer type={"single"} color={"#DBDCDD"}></GCardShimmer>
        </>
      ) : (
        <GView
          style={{ background: "#fff", textAlign: "center", padding: "10px" }}
        >
          <div
            style={{
              width: "28%",
              display: "inline-block",
              borderRight: "1px solid #e2e2e2",
              boxSizing: "border-box",
              verticalAlign: "top",
            }}
          >
            <GView
              style={{
                borderBottom: "1px solid #e2e2e2",
                padding: "5px",
                height: "70px",
              }}
            >
              <GText bold g4 text={"Day"} style={{ fontSize: "17px" }} />
            </GView>
            {timeslotsList &&
              timeslotsList.map((slots, i) => {
                return (
                  <GText
                    key={i}
                    med
                    text={slots.date_display}
                    onClick={() => setSelectedDate(slots.date_display)}
                    style={{
                      margin: "15px 8px 15px 0",
                      textAlign: "center",
                      border:
                        slots.date_display === selectedDate
                          ? "1px solid #000"
                          : "1px solid #e2e2e2",
                      borderRadius: "20px",
                      padding: "5px 8px",
                      width: "auto",
                      cursor: "pointer",
                      background:
                        slots.date_display === selectedDate
                          ? "#000"
                          : "transparent",
                      color:
                        slots.date_display === selectedDate ? "#fff" : "#333",
                      fontSize: "14px",
                    }}
                  />
                );
              })}
          </div>

          <div
            style={{
              width: "36%",
              display: "inline-block",
              boxSizing: "border-box",
              borderRight: "1px solid #e2e2e2",
              verticalAlign: "top",
            }}
          >
            <GView
              style={{
                borderBottom: "1px solid #e2e2e2",
                padding: "5px",
                height: "70px",
              }}
            >
              <GText bold g4 text={"ASAP Slots"} style={{ fontSize: "17px" }} />
              <GText g6 med text={"Pickup & deliver between"} />
            </GView>

            {timeslotsList.map((slots, i) => {
              return (
                <React.Fragment key={i}>
                  {slots.date_display === selectedDate ? (
                    <Div style={{ paddingTop: "5px" }}>
                      {slots.asap_slots.map((t, ind) => {
                        return (
                          <GView
                            key={ind}
                            className={
                              props.orderData?.scheduled_from == t.from &&
                              props.orderData?.scheduled_to == t.to &&
                              props.orderData.scheduled_formatted?.price ==
                                t.price
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              let newOrderData = { ...props.orderData };
                              newOrderData.scheduled_from = t.from;
                              newOrderData.scheduled_to = t.to;
                              t.date = selectedDate;
                              newOrderData.scheduled_formatted = t;
                              props.setOrderData({ ...newOrderData });
                            }}
                            style={{
                              cursor: "pointer",
                              border: "1px solid #e2e2e2",
                              margin: "9px",
                              borderRadius: "5px",
                              padding: "3px",
                            }}
                          >
                            <GText
                              g6
                              semi
                              text={t.from_display + " - " + t.to_display}
                              style={{ letterSpacing: "-0.3px" }}
                            />
                            <GText
                              semi
                              text={
                                props.settings?.currency_symbol +
                                t.price_display
                              }
                              style={{ color: "green" }}
                            />
                          </GView>
                        );
                      })}
                    </Div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            })}
          </div>

          <div
            style={{
              width: "36%",
              display: "inline-block",
              boxSizing: "border-box",
              verticalAlign: "top",
            }}
          >
            <GView
              style={{
                borderBottom: "1px solid #e2e2e2",
                height: "70px",
                padding: "5px",
              }}
            >
              <GText bold g4 text={"FLEX Slots"} />
              <GText g6 med text={"Pickup & delivery anytime between"} />
            </GView>

            {timeslotsList.map((slots, i) => {
              return (
                <React.Fragment key={i}>
                  {slots.date_display === selectedDate ? (
                    <Div style={{ paddingTop: "5px" }}>
                      {slots.flex_slots.map((t, ind) => {
                        return (
                          <GView
                            key={ind}
                            className={
                              props.orderData?.scheduled_from == t.from &&
                              props.orderData?.scheduled_to == t.to &&
                              props.orderData.scheduled_formatted?.price ==
                                t.price
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              console.log(t);
                              let newOrderData = { ...props.orderData };
                              newOrderData.scheduled_from = t.from;
                              newOrderData.scheduled_to = t.to;
                              t.date = selectedDate;
                              newOrderData.scheduled_formatted = t;
                              props.setOrderData({ ...newOrderData });
                            }}
                            style={{
                              cursor: "pointer",
                              border: "1px solid #e2e2e2",
                              margin: "9px",
                              borderRadius: "5px",
                              padding: "3px",
                            }}
                          >
                            <GText
                              g6
                              semi
                              text={t.from_display + " - " + t.to_display}
                              style={{ letterSpacing: "-0.3px" }}
                            />
                            <GText
                              semi
                              text={
                                props.settings?.currency_symbol +
                                t.price_display
                              }
                              style={{ color: "green" }}
                            />
                          </GView>
                        );
                      })}
                    </Div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </GView>
      )}
    </GView>
  );
};

export default OrderTimeslots;
