import React, { useState, useEffect } from "react";
import GView from "../Elements/GView";
import RecentAddress from "../assets/images/recent.png";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GText from "../Elements/GText";
import GTaxiMap from "./orderStopsMap";

const OrderStopsLocations = (props) => {
  // console.log(props);
  const [addressLocation] = useState({ lat: 33.7529, lng: -84.4036 });

  return (
    <>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.seIsLocAddressVisible(false)}
        />{" "}
        Choose location
      </div>

      <div
        className="modal-body"
      // style={{ padding: "5px 0" }}
      >
        <GTaxiMap
          center={{ lat: 33.7529636, lng: -84.4036709 }}
          zoom={15}
          places={[]}
          isAutoComplete={true}
          showMap={false}
          style={{ height: "0px", position: "relative" }}
          onChange={(address) => {
            let newAddress = {
              lat: address.lat,
              lng: address.lng,
              line1: address.line1,
              formatted_display:address.formatted_display,
              city: address.city,
              state: address.state,
              country: address.country,
              zipcode: address.zipcode,
            };
            props.onSelectAddress(newAddress);
          }}
        />

        <GView>
          {props.addressData.map((address, i) => {
            return (
              <GView
                key={i}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "20px 10px",
                  borderTop: i > 0 ? "1px solid #f2f2f2" : "",
                  cursor: "pointer",

                  // "&:hover": {
                  //   background: "#f5f5f5",
                  // },
                }}
                onClick={() => {
                  props.onSelectAddress(address);
                }}
              >
                <img
                  src={RecentAddress}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                <GView>
                  <GText
                    semi
                    text={`${address.location_name} (${address.title})`}
                    style={{
                      textTransform: "uppercase",
                      paddingBottom: "3px",
                      color: "#333",
                      fontSize: "15.5px",
                      fontWeight: "550",
                    }}
                  />
                  <GText
                    dark
                    text={address.formatted_display}
                    style={{ fontSize: "14px" }}
                  />
                </GView>
              </GView>
            );
          })}
        </GView>
      </div>
    </>
  );
};

export default OrderStopsLocations;
