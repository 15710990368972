import React, { useState, useEffect, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "notistack";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GButton from "../Elements/GButton";
import GRouteAddress from "../Elements/GRouteAddress";
import { GCardShimmer } from "../Elements/gShimmer";
import { GInlineContent, GTableContent } from "../Elements/gContent";
import Modal from "react-modal";
import CancelReasonsView from "../Components/orderCancelReasons";
import OrderMessages from "../Components/orderMesaages";
import OrderFeedback from "../Components/orderFeedback";
import OrderTrack from "../Components/googleMap";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { createSocketConnection } from "../DataFactory/useLaravelPusher";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? match[1] : undefined;
}

export default function OrderDetail(props) {
  const [orderData, setOrderData] = useState({});
  const [omvisible, setOmvisible] = useState(false);
  const [ocrvisible, setOcrVisible] = useState(false);
  const [ofvisible, setOfvisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderMessages, setOrderMessages] = useState([]);
  const {
    status,
    tasks,
    payment_summary,
    order_review,
    order_fields,
    order_items,
    cancelled_log,
  } = orderData;
  const { enqueueSnackbar } = useSnackbar();

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "c/orders/" + props.linkedId })
      .then((response) => {
        console.log("gh" + response);

        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            response.data.data.order_fields.map((o) => {
              if (o.type == "document") {
                o.value = o.value ? JSON.parse(o.value) : o.value;
              }
            });
            setOrderData(response.data.data);
            setOrderMessages(response.data.data?.order_messages || []);
            setIsLoading(false);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, [props.linkedId]);

  useEffect(() => {
    createSocketConnection();

    if (orderData?.id) {
      window.Echo.channel(`order-updated.${props.linkedId}`).listen(
        "OrderUpdated",
        function (e) {
          console.log(e);
          if (e.order?.id == orderData?.id) {
            setOrderData({ ...orderData, ...e.order });
          }
        }
      );
    }
    return () => {
      window.Echo.leaveChannel(`order-updated`);
    };
  }, [orderData]);

  if (props.orderInfovisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOrderInfovisible(false)}
        />{" "}
        Order Detail
        {/* {status?.title === "Pending" && (
          <GButton
            variant="linkable"
            children="Problem?"
            type="button"
            style={{ marginLeft: "auto" }}
            onClick={() => setOcrVisible(true)}
          />
        )} */}
        {status?.title === "Completed" && !order_review ? (
          <GButton
            variant="linkable"
            children="Feedback"
            type="button"
            style={{ marginLeft: "auto" }}
            onClick={() => setOfvisible(true)}
          />
        ) : (
          ""
        )}
      </div>

      <div className="modal-body" style={{ padding: "0 25px" }}>
        {isLoading === true ? (
          <GCardShimmer />
        ) : (
          <React.Fragment>
            <GView
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px 0",
              }}
            >
              <GText
                g4
                semi
                text={"Order #" + (orderData?.slug || orderData.id)}
                style={{ margin: "0px 0" }}
              />
              <GText
                g5
                med
                text={"Created At : " + orderData.created}
                style={{ margin: "0px 0", fontSize: "13.5px" }}
              />
            </GView>

            <OrderTrack
              taskData={tasks}
              agent={orderData.agent}
              status={status}
              setOmvisible={setOmvisible}
            />

            {status?.title === "Cancelled" && (
              <GText
                style={{
                  padding: "10px",
                  color: "#F96D77",
                  fontWeight: "450",
                  letterSpacing: "0.2px",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                  fontSize: "13.5px",
                  marginBottom: "10px",
                  background: "rgba(249, 109, 119, 0.2)",
                }}
                text={cancelled_log.reason}
              />
            )}
            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div style={{ paddingBottom: "10px" }}>
              <GText
                g6
                med
                text={"Tasks"}
                style={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  margin: "15px 0 10px",
                }}
              />
              <GRouteAddress
                task={tasks}
                status={true}
                orderId={orderData?.slug || orderData?.id}
                showInfo={true}
              />
            </div>

            {order_items && order_items.length > 0 && (
              <GView style={{ paddingBottom: "10px" }}>
                <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
                <GText
                  g6
                  med
                  text={"Order Items"}
                  style={{
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    margin: "15px 0 10px",
                  }}
                />

                {order_items.map((item, i) => {
                  return (
                    <GText
                      key={i}
                      text={item.display}
                      style={{ margin: "10px 0" }}
                    />
                  );
                })}
              </GView>
            )}

            {(order_fields && order_fields.length > 0) ||
            orderData?.vehicle_type_id ? (
              <>
                <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
                <div style={{ paddingBottom: "10px" }}>
                  <GText
                    g6
                    med
                    text={"Order Info"}
                    style={{
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      margin: "15px 0 10px",
                    }}
                  />
                  {orderData?.vehicle_type_id && (
                    <GInlineContent
                      title="vehicle Type"
                      value={orderData?.vehicle_type_title}
                      textTransform="capitalize"
                    />
                  )}

                  {orderData.schedule_time && (
                    <GInlineContent
                      title="Schedule Time"
                      value={orderData.schedule_time}
                    />
                  )}

                  {orderData?.special_instructions && (
                    <GInlineContent
                      title="Special Instructions"
                      value={
                        orderData?.special_instruction_display ||
                        orderData?.special_instructions
                      }
                    />
                  )}

                  {order_fields.map((field, i) => {
                    return (
                      <>
                        {field.type == "image" ? (
                          <div style={{ margin: "10px 0" }}>
                            <GText
                              semi
                              text={field.title}
                              style={{ margin: "5px 0" }}
                            />
                            {field.value ? (
                              <img
                                src={`${ImgUrl("form")}/${field.value}`}
                                style={{ maxWidth: "100%", height: "100px" }}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        ) : field.type == "document" ? (
                          <div style={{ margin: "10px 0" }}>
                            <GText
                              semi
                              text={field.title}
                              style={{ margin: "5px 0" }}
                            />
                            <a
                              target="_blank"
                              href={`${ImgUrl("form")}/${field.value?.path}`}
                              style={{ wordBreak: "break-word" }}
                            >
                              {ImgUrl("form")}/{field.value?.path}
                            </a>
                          </div>
                        ) : (
                          <GInlineContent
                            key={i}
                            title={field.title}
                            value={field.value}
                            textTransform="capitalize"
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              ""
            )}

            {orderData?.onerail_tracking_link && (
              <>
                <GView style={{ paddingBottom: "10px" }}>
                  {/* <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} /> */}
                  {/* <GText
                  g6
                  med
                  text={"Onerail Tracking Link"}
                  style={{
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    margin: "15px 0 10px",
                  }}
                /> */}
                  <a
                    target="_blank"
                    href={orderData?.onerail_tracking_link}
                    style={{ wordBreak: "break-word", fontSize: "15px" }}
                  >
                    {"Live Order Tracking"}
                  </a>
                </GView>
              </>
            )}

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div>
              <GText
                g6
                med
                text={"Payment Summary"}
                style={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  margin: "15px 0 10px",
                }}
              />
              {payment_summary?.data.map((p) => {
                return (
                  <GTableContent
                    key={p.title}
                    title={p.title}
                    value={props.settings.currency_symbol + "" + p.value}
                    align="right"
                    lAlign="right"
                    padding="2px 5px"
                  />
                );
              })}
              <hr style={{ border: "0", borderTop: "1px solid #e2e2e2" }} />

              <GTableContent
                title="Grand Total"
                value={
                  props.settings.currency_symbol +
                  "" +
                  payment_summary?.total_display
                }
                align="right"
                lAlign="right"
                fontSize="15px"
                color="#000"
              />
            </div>
          </React.Fragment>
        )}
        {getIOSVersion() >= 14 && <div style={{ height: "80px" }}></div>}
      </div>

      <Modal
        isOpen={ocrvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcrVisible(false)}
        style={{
          content: {
            left: "auto",
            right: "0",
            top: "0",
            bottom: "0",
            width: "450px",
          },
        }}
        contentLabel="Order cancel Modal"
      >
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrVisible={setOcrVisible}
          linkedId={orderData.id}
          onSubmit={() => {
            getOrderDetail();
            props.onCancelOrder();
          }}
        />
      </Modal>

      <Modal
        isOpen={omvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOmvisible(false)}
        style={{
          content: {
            left: "auto",
            right: "0",
            top: "0",
            bottom: "0",
            width: "450px",
            padding: "20px 0 0",
          },
        }}
        contentLabel="Order message Modal"
      >
        <OrderMessages
          omvisible={omvisible}
          setOmvisible={setOmvisible}
          orderId={orderData.id}
          orderSlug={orderData?.slug || orderData.id}
          data={orderMessages}
          onSubmit={(data) => {
            console.log([...orderMessages, data]);
            setOrderMessages([...orderMessages, data]);
          }}
        />
      </Modal>

      <Modal
        isOpen={ofvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOfvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Order Feedback Modal"
      >
        <OrderFeedback
          ofvisible={ofvisible}
          setOfvisible={setOfvisible}
          orderId={orderData.id}
          onSubmit={getOrderDetail}
        />
      </Modal>
    </React.Fragment>
  );
}
