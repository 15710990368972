import React, { useState } from "react";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { useSnackbar } from "notistack";

export default function ApiTokenMessage(props) {
  console.log(props)
  const { enqueueSnackbar } = useSnackbar();

  if (props.tokenAddedVisible === false) {
    return null;
  }
  return (
    <div className="modal-body" style={{textAlign:"center",padding:"5px 20px"}}>
      <GView style={{background:"#f4f5f9",border:"1px solid #e2e2e2",padding:"10px"}}>
        <GText g6 text={props.response?.data?.token?.accessToken} style={{lineBreak:"anywhere"}}/>
      </GView>
      <GButton variant="condensed"
        onClick={(e) => {

            if (navigator.clipboard != undefined) {
              //Chrome
              navigator.clipboard.writeText(props.response?.data?.token?.accessToken).then(
                function () {
                  console.log("Async: Copying to clipboard was successful!");
                },
                function (err) {
                  console.error("Async: Could not copy text: ", err);
                }
              );
            } else if (window.clipboardData) {
              // Internet Explorer
              window.clipboardData.setData("Text", props.response?.data?.token?.accessToken);
            } else {
              console.log("cc");

              var TempText = document.createElement("input");
              TempText.value = props.response?.data?.token?.accessToken;
              document.body.appendChild(TempText);
              TempText.select();
              
              document.execCommand("copy");
              document.body.removeChild(TempText);
              e.target.focus();
            }
          enqueueSnackbar("Copied");
        }}
         style={{width:"auto",margin:"15px 0 20px",borderRadius:"5px"}}>Copy Token</GButton>
        

         <GText text={props.response?.message} style={{fontSize:"14px"}}/>
      
      <br/>
      <GButton variant="linkable" children={"Dismiss"} style={{textDecoration:"underline"}}onClick={()=>props.setTokenAddedVisible(false)}/>
    </div>
  );
}
