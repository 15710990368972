import React, { useState, useContext } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import AddCard from "../paymentComponents/addCard";

export default function CardForm(props) {
  const { settings } = useContext(SettingsContext);

  if (props.cardFvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setCardFvisible(false)}
        />
        {props.isEmpty === true ? "Create" : "Edit"} Card
      </div>
      <div
        className="modal-body"
        style={{
          padding: "20px",
          maxHeight: "calc(100vh - 80px)",
          overflow: "auto",
        }}
      >
        <AddCard
          onCardAdd={() => {
            props.setCardFvisible(false);
            props.onSubmit();
          }}
          // isEmpty={props.isEmpty}
          data={props.data}
          setAddCard={() => props.setCardFvisible(false)}
          cardType={settings?.card?.identifier || ""}
        />
      </div>
    </React.Fragment>
  );
}
