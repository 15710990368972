import React, { useContext, useState } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import ApiTokenResponseView from "../Components/apiTokenMessage";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import { Formik, Form } from "formik";
import FormikControl from "../formikComponents/formikControl";
import useDataFctory from "../useDataFactory";
import { TableContainer } from "@mui/material";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import GConfirmDialog from "../Elements/gConfirmDialog";

const columns = [
  { id: "token_name", label: "Token Name", minWidth: 100 },
  {
    id: "expires",
    label: "Expires",
    minWidth: 120,
    align: "center",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 80,
    align: "center",
  }
];


const AddTokenDiv = styled.div`
  padding: 0 10px;
  form{
    display:flex;align-items:end;
  }
`;

export default function ApiTokens() {
  const { settings} = useContext(SettingsContext);
  const [tokenAddedVisible, setTokenAddedVisible] = useState(false);
  const[tokenAddedResponse,setTokenAddeResponse] = useState("")
 const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
   const { width } = useWindowSize();
   const [dialogOpen, setDialogOpen] = useState(false);
   const [selectedTokenId,setSelectedTokenId] = useState("")
   const {
    loading,
    Shimmer,
    Placeholder,
    data,
    refreshData,
  } = useDataFctory("api_tokens", false);

   const initialValues = {
    name:  "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    let sendData = { ...values };
    console.log(sendData);

    ApiService({ method: "POST", route: "c/pa-tokens", body: sendData }).then(
      (response) => {
        console.log("r" + response.data.status_code);
        if (response.data.status_code === 1) {
          setTokenAddeResponse(response.data)
          setTokenAddedVisible(true);
          refreshData();
          document.getElementById("resetButton").click();
        }else{
          enqueueSnackbar(response.data?.message)
        }
        setBtnLoading(false);
        return;
      }
    );
  };

  const handleDelete = () => {
    ApiService({
      method: "DELETE",
      route: "c/pa-tokens/" + selectedTokenId,
      body: "",
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <GText g2="true" theme bold text={"API Tokens"} />
      <GView
        style={{
          margin: "10px 0",
        }}
      >
        <GView
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: width <= 767 ? "column" : "row",
          }}
        >
          <GView
            style={{
              maxWidth: width <= 991 ? "250px" : "300px",
            }}
          >
            <GText
              h5 dark
              text={"You can generate an API token to integrate your business with delivAI."}
              style={{ margin: "10px 0", letterSpacing: "0" }}
            />

              <GText dark h5 text={"You will need these tokens to authenticate over the HTTPS APIs."}   style={{ margin: "10px 0", letterSpacing: "0" }}/>
           
           <p>Please read the API docs <a target="_blank" href="https://delivai.readme.io/reference/getting-started-with-your-api">here.</a></p>
          </GView>
          <GView
            style={{
              flex: "1 1 1%",
              paddingLeft: width <= 767 ? "0" : "20px",
              paddingTop: width <= 767 ? "20px" : "0",
            }}
          >
            <AddTokenDiv>
              <GText
                g4
                semi
                text={"Add a API Token"} 
              />
              <GText
                dark
                g5
                text={
                  "Enter the name of your Token, and we will return a unique token."
                }
                style={{ letterSpacing: "0", margin: "7px 0" }}
              />

<Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ resetForm }) => (
                <Form>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Token Name"
                    name="name"
                    divstyle={{paddingLeft:0,margin:0}}
                  />
                  <GButton
                    variant="condensed"
                    children="Generate"
                    style={{ margin: "7px 15px", width: "auto",padding:"11px 20px" }}
                    type="submit"
                    loading={isBtnLoading}
                  />
                   <button type="button"
                            id="resetButton"
                            style={{ display: "none" }}
                            onClick={() => resetForm()}
                          ></button>
                </Form>
              )}
            </Formik>
             
            </AddTokenDiv>
          </GView>
        </GView>

<GView style={{marginTop:"20px",width:"420px",background:"#f4f5f9"}}>
        {loading === true ? (
        <Shimmer></Shimmer>
      ) : data && data.length> 0 ? (
       
        <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          className="table-separate"
          style={{border:0}}
        >
          <TableHead>
            <TableRow style={{ background: "transparent" }}>
              {columns.map((c) => {
                return (
                  <TableHeader
                    className="light"
                    key={c.id}
                    style={{
                      minWidth: c.minWidth,
                      maxWidth: c.maxWidth,
                      width: c.width,
                      textAlign: c.align,
                      zIndex: "8",
                    }}
                  >
                    {c.label}
                  </TableHeader>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((s, i) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={s.id}style={{padding:"7px 12px"}}
                >
                  <TableRowData>
                   {s?.name || "-"}
                  </TableRowData>
                  <TableRowData style={{ textAlign: "center",padding:"7px 12px" }}>
                    {s.expiry_display || "-"}
                  </TableRowData>
                  <TableRowData style={{ textAlign: "center",padding:"7px 12px" }}>
                    <GButton variant="linkable" onClick={()=>{
                      setSelectedTokenId(s.id);
                      setDialogOpen(true)
                    }}><Delete style={{width:"17px"}}/></GButton>
                  </TableRowData>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>): ""
      }
      </GView>
      </GView>

        {dialogOpen === true && (
          <GConfirmDialog
            open={dialogOpen}
            title="Delete API Token"
            text="Are you sure you want to delete API Token?"
            handleClose={() => setDialogOpen(false)}
            onConfirmClose={() => {
              setDialogOpen(false);
              handleDelete();
            }}
          ></GConfirmDialog>
        )}

      <Modal
        isOpen={tokenAddedVisible}
        className="modal"
        overlayClassName="modal-overlay"
        // onRequestClose={() => setTokenAddedVisible(false)}
        style={{
          content: {
            width: "500px",
            top: "0%",
            left: "0%",
            right: "0",
            bottom: "0",
            margin: "auto",
            maxHeight: "580px",overflow:"auto"
          },
        }}
        contentLabel="Token Added Message Modal"
      >
        <ApiTokenResponseView
          tokenAddedVisible={tokenAddedVisible}
          setTokenAddedVisible={setTokenAddedVisible}
          response={tokenAddedResponse}
        />
      </Modal>
    </div>
  );
}
