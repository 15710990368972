import React, { useState, useEffect, useContext, useRef } from "react";
import { TableContainer } from "@mui/material";
import { csvFileApiService } from "../services";
import { FlatList, View, Platform } from "react-native";
import { SettingsContext } from "../DataFactory/useSettings";
import OrderInfo from "./orderDetail";
import useDataFctory from "../useDataFactory";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import GRouteAddress from "../Elements/GRouteAddress";
import GInfo from "../Elements/gInfo";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { useSnackbar } from "notistack";
import DownloadCsvImage from "../assets/images/download-csv.png";
import sampleFile from "../assets/files/sample-bulk-orders.csv";
import { createSocketConnection } from "../DataFactory/useLaravelPusher";

const columns = [
  { id: "id", label: "Order ID", minWidth: 60 },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
    align: "center",
  },
  {
    id: "deliver_to",
    label: "Deliver To",
    minWidth: 120,
    align: "center",
  },
  {
    id: "scheduled_for",
    label: "Scheduled For",
    minWidth: 120,
    align: "center",
  },
  {
    id: "price",
    label: "Price",
    minWidth: 80,
    align: "center",
  },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 130,
    align: "center",
  },
];

export default function Orders(props) {
  const { width } = useWindowSize();
  const { settings } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [orderInfovisible, setOrderInfovisible] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(
    props.location?.state?.order_id || ""
  );
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("order", true, {
    order_status: settings?.theme == 3 ? "inactive" : "",
  });
  const fileInput = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [ordersData, setOrderData] = useState([])

  useEffect(() => {
    setBtnDisabled(false);
    setOrderData([...data]);
  }, [data]);

  useEffect(() => {
    createSocketConnection();

    if (ordersData.length > 0 && data.length > 0) {
      window.Echo.channel(`customer-updates.${settings?.user?.id}`).listen("OrderUpdated", function (e) {
        console.log(e);
        let findInd = ordersData.findIndex((o) => o.id == e.order?.id);
        if (findInd > -1) {
          ordersData[findInd] = e.order
          setOrderData([...ordersData])
        }
      });
    }
    // window.Echo.channel(`customer-updates.${settings?.user?.id}`).listen("OrderPlaced", function (e) {
    //   console.log(e);
    //   let findInd = ordersData.findIndex((o) => o.id == e.order?.id);
    //   if (findInd > -1) {
    //     ordersData[findInd] = e.order;
    //   }
    // });
    return () => {
      window.Echo.leaveChannel(`customer-updates`);
    };

  }, [ordersData]);

  useEffect(() => {
    console.log(props?.location?.state);
    if (props.location?.state?.order_id) {
      setSelectedOrderId(props.location?.state?.order_id);
      setTimeout(function () {
        setOrderInfovisible(true);
      }, 1000);
    }
  }, []);

  let count = Math.round(width / (Platform.OS === "web" ? 600 : 300));

  function uploadBulkOrders(file) {
    setIsUploading(true);
    csvFileApiService("POST", "bulk/orders", {
      csv: file,
      customer_id: settings?.user?.id,
    })
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          refreshData();
        }
        setIsUploading(false);
        fileInput.current.value = "";
        enqueueSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      <GView
        style={{ display: "flex", margin: "10px 5px", alignItems: "center" }}
      >
        <GText g2="true" theme bold text={"All Orders"} />
        {data.length > 0 && (
          <>
            &nbsp;
            <GText
              g5
              med
              semi
              text={
                pagination.total > 1
                  ? " (" + pagination?.total + " Orders)"
                  : " (" + pagination?.total + " Order)"
              }
              style={{ fontStyle: "italic" }}
            />
          </>
        )}

        {settings?.user?.account_type == "business" && settings?.user?.bulk_active == 1 && (
          <div
            style={{
              marginLeft: "auto",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              id="orderFile"
              ref={fileInput}
              onChange={(e) => {
                console.log(e.target.files[0]);
                if (e.target.files[0]) {
                  uploadBulkOrders(e.target.files[0]);
                }
              }}
              style={{ display: "none" }}
            />
            <a
              href={sampleFile}
              download
              style={{
                textDecoration: "underline",
                color: "#333",
                fontSize: "14px",
                margin: "5px 15px",
                fontStyle: "italic",
              }}
            >
              Download Sample File
            </a>

            <GButton
              variant="outlined"
              children="Bulk Upload"
              loading={isUploading}
              onClick={() => {
                document.getElementById("orderFile").click();
              }}
              style={{
                // marginLeft: "auto",
                padding: "0.5rem 1rem ",
              }}
            />

            {/* <a
              href={sampleFile}
              title="Download Sample File"
              download
              style={{
                textDecoration: "underline",
                color: "#333",
                fontSize: "14px",
                margin: "5px 15px",
              }}
            >
              <img src={DownloadCsvImage} style={{ width: "30px" }} />
            </a> */}
          </div>
        )}
      </GView>
      {loading === true ? (
        <Shimmer></Shimmer>
      ) : (
        <React.Fragment>
          {settings.user?.account_type == "business" ? (
            <GView
              style={{
                // boxShadow: "0 0 10px #cccccc85",
                // padding: "20px 0 ",
                margin: "18px 0",
                background: "#f4f5f9",
              }}
            >
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="table-separate"
                >
                  <TableHead>
                    <TableRow style={{ background: "transparent" }}>
                      {columns.map((c) => {
                        return (
                          <TableHeader
                            className="light"
                            key={c.id}
                            style={{
                              minWidth: c.minWidth,
                              maxWidth: c.maxWidth,
                              width: c.width,
                              textAlign: c.align,
                              zIndex: "8",
                            }}
                          >
                            {c.label}
                          </TableHeader>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ordersData.map((s, i) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={s.id}
                        >
                          <TableRowData>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedOrderId(s?.id);
                                setOrderInfovisible(true);
                              }}
                            >
                              #{s?.slug || s.id}
                            </a>
                          </TableRowData>
                          <TableRowData style={{ textAlign: "center" }}>
                            <span
                              className="orderStatus"
                              style={{
                                opacity: "1",
                                color: "#" + s.status.color1,
                                background: "#" + s.status.color2,
                                padding: "3px 12px",
                                // borderRadius: "20px",
                                fontSize: "14px",
                                textTransform: "uppercase",
                                fontWeight: 500,
                              }}
                            >
                              {s.status.title}
                            </span>
                          </TableRowData>
                          <TableRowData style={{ textAlign: "center" }}>{s?.tasks?.find(t => t.type == 'delivery')?.location_name}</TableRowData>
                          <TableRowData style={{ textAlign: "center" }}>{s?.schedule_time}</TableRowData>
                          <TableRowData style={{ textAlign: "center" }}>
                            {settings.currency_symbol}
                            {s.amount_display}
                          </TableRowData>
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.created}
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </GView>
          ) : (
            <View style={{ flex: 1, flexDirection: "row" }}>
              <FlatList
                key={
                  width <= 767
                    ? "s"
                    : width > 767 && width < 900
                      ? "m"
                      : width >= 900 && width < 1500
                        ? "l"
                        : "xl"
                }
                initialNumToRender={ordersData.length}
                contentContainerStyle={{ flex: 1 }}
                keyExtractor={(data) => data.id}
                data={ordersData}
                numColumns={count}
                renderItem={({ item }) => (
                  <div style={{ flex: 1 / count }}>
                    <div
                      onClick={() => {
                        setSelectedOrderId(item?.id);
                        setOrderInfovisible(true);
                      }}
                      style={{
                        background: "#fff",
                        padding: "12px 12px 20px",
                        margin: "8px",
                        cursor: "pointer",
                        border: "1px solid #f2f2f2",
                        height: "calc(100% - 54px)",
                      }}
                    >
                      <GInfo
                        title={"#" + (item?.slug || item?.id) + " - " + item?.status?.title}
                        rightTitle={
                          settings.currency_symbol + item?.amount_display
                        }
                        subtitle2={item?.created}
                        titleTheme={true}
                        titleStyle={{
                          color: "#" + item.status?.color1,
                          background: "#" + item.status?.color2,
                          display: "inline-block",
                          padding: "2px 12px",
                          fontSize: "15px",
                          border: "1px solid #" + item.status?.color1,
                          textTransform: "uppercase",
                        }}
                      />
                      <hr
                        style={{
                          borderTop: "0",
                          borderBottom: "1px dashed #bbb",
                        }}
                      />
                      <GRouteAddress task={item.tasks} />
                    </div>
                  </div>
                )}
                ListEmptyComponent={Placeholder}
              />
            </View>
          )}

          {pagination.next_page_url && (
            <GButton
              loading={btnDisabled}
              variant="condensed"
              children="Load More"
              type="button"
              onClick={() => {
                setBtnDisabled(true);
                loadMore();
              }}
              style={{
                margin: "10px auto",
                width: "150px",
                justifyContent: "center",
                display: "flex",
              }}
            />
          )}
        </React.Fragment>
      )}
      <Modal
        isOpen={orderInfovisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setOrderInfovisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="Order Detail Modal"
      >
        <OrderInfo
          orderInfovisible={orderInfovisible}
          setOrderInfovisible={setOrderInfovisible}
          linkedId={selectedOrderId}
          settings={settings}
          onCancelOrder={() =>
            refreshData({
              order_status: settings?.theme == 3 ? "inactive" : "",
            })
          }
        />
      </Modal>
    </React.Fragment>
  );
}
