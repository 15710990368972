import React, { createContext, useEffect, useState } from "react";
import { ApiService, ApiLoginService } from "../services";
import useLocalStorage from "./useLocalStorage";

const SettingsContext = createContext(null);

const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({}); //useLocalStorage('settings');
  console.log(settings);
  useEffect(() => {
    console.log("aa");
    if (Object.keys(settings).length > 0) {
      return;
    }
    refreshSettings();
  }, []);

  const refreshSettings = () => {
    var token = localStorage.getItem("wAccessToken");
    var rtoken = localStorage.getItem("wRefreshToken");
    var expires = localStorage.getItem("wExpiresIn");
    if (token && rtoken && expires && expires > 0) {
      ApiService({ method: "GET", route: "c/me" }).then((response) => {
        if (response.data) {
          setSettings(response.data.data);  
           localStorage.setItem("settings",JSON.stringify(response.data.data))
      
          console.log(response.data.data);
        }
      });
    } else {
      ApiLoginService("GET", "c/settings").then((response) => {
            setSettings(response.data || {});
        localStorage.setItem("settings",JSON.stringify(response.data))
        return response;
      });
    }
  };

  return (
    <SettingsContext.Provider
      value={{ settings, setSettings, refreshSettings }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };
