import React from "react";
import GButton from "./Elements/GButton";
import GText from "./Elements/GText";
import GView from "./Elements/GView";

const ErrorView = (props) => {
  return (
    <GView
      style={{
        display: "flex",
        minHeight: "50vh",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <GText
        g1
        bold
        text={"Network Error"}
        style={{ fontSize: "35px", color: "#bbb" }}
      />
      <pre
        style={{
          fontSize: "15px",
          lineHeight: "22px",
          whiteSpace: "pre-line",
          color: "#333",
        }}
      >
        {props.error.message}
      </pre>
      <GButton
        variant="outlined"
        children={"Try Again"}
        onClick={() => props.resetErrorBoundary()}
        style={{ fontWeight: "600", margin: "10px auto", borderRadius: "20px" }}
      />
    </GView>
  );
};

export default ErrorView;
